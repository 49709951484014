<template>
  <div class="font-inter flex flex-col gap-8 products">
    <div>
      <h1 class="text-2xl font-bold pb-2 font-nunito">Gerenciar produtos</h1>
      <p class="text-gray-500 text-sm">Adicione, edite e exclua produtos</p>
    </div>
    <div class="grid grid-cols-12 gap-4 products__filter">
      <form @submit.prevent class="rounded-xl w-full relative col-span-7 products__filter__search">
        <input type="text" placeholder="Busque pelo nome do produto" class="rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11" />
        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z"
            fill="#676767"
          />
        </svg>
      </form>
      <!-- <div class="col-span-5 w-full products__filter__select-organization">
                <MySelect :id="'organizationFilter'" placeholder="@ Selecione a organização" />
            </div> -->
    </div>
    <table class="w-full">
      <thead class="grid grid-cols-8 p-4">
        <th class="col-span-1">#ID</th>
        <th class="col-span-1">Nome</th>
        <th class="col-span-1">Preço</th>
        <th class="col-span-1">Vendas</th>
        <th class="col-span-1">Reembolsos</th>
        <th class="col-span-1">Cadastrado</th>
        <th class="col-span-1">Proprietário</th>
        <th class="col-span-1">Ações</th>
      </thead>
      <tbody class="flex flex-col gap-4">
        <tr v-for="product in products?.data" class="grid grid-cols-8 border rounded-xl p-4 product-tr">
          <td class="col-span-1">{{ product.id }}</td>
          <td class="col-span-1">{{ product.title }}</td>
          <td class="col-span-1">
            {{
              product.priceMin
                ? product.priceMin === product.priceMax
                  ? product.priceMin.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : product.priceMin.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }) +
                    ' a ' +
                    product.priceMax.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                : '-'
            }}
          </td>
          <td class="col-span-1">{{ product.totalSales }}</td>
          <td class="col-span-1">{{ product.refundPeriod }}</td>
          <td class="col-span-1">
            {{ new Date(product.createdAt).toLocaleDateString('pt-BR') }}
          </td>
          <td class="col-span-1">
            <RouterLink
              class="hover:text-primary transition-color duration-100"
              :to="{
                name: 'organizations.edit',
                params: { organizationId: product.organizationId },
              }"
            >
              {{ product.organizationName }}
            </RouterLink>
          </td>
          <td class="col-span-1">
            <RouterLink
              class="flex hover:stroke-primary"
              :to="{
                name: 'products.edit',
                params: {
                  productId: product.id,
                  organizationId: product.organizationId,
                },
              }"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary transition-all duration-200"
                />
                <path
                  d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary transition-all duration-200"
                />
                <path
                  d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary transition-all duration-200"
                />
              </svg>
            </RouterLink>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination v-if="products?.pagination" :data="products?.pagination" :paginate="getProducts" />
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import axiosClient from '@/api/axios'
  import Pagination from '@/components/pagination/Pagination.vue'
  import IProduct from '@/interfaces/product/IProduct'
  import IPagination from '@/interfaces/pagination/IPagination'

  const products = ref<IPagination<IProduct>>()

  function getProducts(page?: number) {
    const params: { page?: number } = {}

    if (page) {
      params.page = page
    }

    axiosClient.get('/administrator/organizations/products', { params }).then((res: any) => {
      products.value = res.data
    })
  }

  onMounted(() => {
    getProducts()
    console.log(products)
  })
</script>

<style lang="scss">
  .products {
    input[type='text'] {
      height: 55px;
      border: none;
      border-radius: 16px;
      background: #f5f5f5;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      transition: background 0.2s;
      &:focus {
        border: none !important;
        outline: none !important;
      }
      &:focus::placeholder {
        color: transparent;
        border: none !important;
      }
    }

    .product-tr {
      border: 1px solid #d3d3d3 !important;
      border-radius: 16px;
      min-height: 100px;
      align-items: center;
    }
  }

  #dropdown {
    z-index: 99;
    opacity: 0;
    transition: all 0.2s;
    animation: zeroScaleDropdownAnimation 1s forwards;

    a {
      transition: all 0.2s;
      &:hover {
        background: #dddcef;
      }
    }
  }

  #dropdown.show {
    opacity: 1;
    animation: scaleDropdownAnimation 1s forwards;
  }

  @keyframes scaleDropdownAnimation {
    to {
      transform: scale(1);
    }
  }

  @keyframes zeroScaleDropdownAnimation {
    to {
      transform: scale(0);
    }
  }

  @media screen and (max-width: 600px) {
    .products {
      thead {
        display: none;
      }
      tr {
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 1rem;
        td {
          text-align: start !important;
          a {
            justify-content: start;
          }
        }
      }
      &__filter {
        grid-template-columns: 1fr;
        &__select-organization,
        &__search {
          grid-column: unset;
        }
      }
      #td-contact {
        padding-bottom: 1rem;
      }
    }
  }
</style>
