<template>
  <div class="grid grid-cols-12 gap-4 pb-8">
    <div class="grid grid-cols-12 col-span-12 p-4 gap-6 border rounded-xl">
      <div class="col-span-12">
        <h3 class="text-1xl flex items-center gap-2 font-bold">
          Geral
          <svg class="fill-292" viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
            <path
              d="M19.7 12.9L14 18.6H11.7V16.3L17.4 10.6L19.7 12.9M23.1 12.1C23.1 12.4 22.8 12.7 22.5 13L20 15.5L19.1 14.6L21.7 12L21.1 11.4L20.4 12.1L18.1 9.8L20.3 7.7C20.5 7.5 20.9 7.5 21.2 7.7L22.6 9.1C22.8 9.3 22.8 9.7 22.6 10C22.4 10.2 22.2 10.4 22.2 10.6C22.2 10.8 22.4 11 22.6 11.2C22.9 11.5 23.2 11.8 23.1 12.1M3 20V4H10V9H15V10.5L17 8.5V8L11 2H3C1.9 2 1 2.9 1 4V20C1 21.1 1.9 22 3 22H15C16.1 22 17 21.1 17 20H3M11 17.1C10.8 17.1 10.6 17.2 10.5 17.2L10 15H8.5L6.4 16.7L7 14H5.5L4.5 19H6L8.9 16.4L9.5 18.7H10.5L11 18.6V17.1Z"
            ></path>
          </svg>
        </h3>
        <p class="text-sm">Campos sobre o contrato</p>
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">URL de contrato</label>
        <input type="text" v-model="contractForm.contractUrl" placeholder="ex: http://contrato.com/contrato" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Juros por parcela (%)</label>
        <input type="number" v-model="contractForm.interestCommission" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa de antecipação ao mês (%)</label>
        <input type="number" v-model="contractForm.anticipationFee" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Custo de saque (%)</label>
        <input type="number" v-model="contractForm.platformCostWithdraw" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <!-- <div class="flex flex-col gap-2 col-span-6">
                <label for="" class="font-semibold text-sm input-primary">Intervalo de pagamento de saque</label>
                <input type="number" v-model="contractForm.payoutWithdrawInterval" placeholder="ex: 8" class="border border-d5 p-3 rounded-xl w-full input-primary">
            </div> -->
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa de anti fraude (%)</label>
        <input type="number" v-model="contractForm.antiFraudFee" placeholder="ex: 15" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Pagamento mínimo</label>
        <input type="number" v-model="contractForm.paymentMinimum" placeholder="ex: 20,00" class="border border-d5 p-3 rounded-xl w-full input-primary" disabled />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Anti fraude</label>
        <MySwitch :id="'antiFraud'" :checked="contractForm.antiFraud == 'active'" :setChecked="(v) => contractForm.antiFraud = v ? 'active' : 'inactive'" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Transferência automática</label>
        <MySwitch :id="'automaticTransfer'" :checked="contractForm.automaticTransfer!" :setChecked="(v) => contractForm.automaticTransfer = v" />
      </div>
    </div>
    <div class="grid grid-cols-12 col-span-12 p-4 gap-6 border rounded-xl">
      <div class="col-span-12">
        <h3 class="col-span-12 text-1xl flex items-center gap-2 font-bold">
          Cartão de crédito
          <svg class="fill-292" viewBox="0 0 24 24" role="presentation" width="22" height="22">
            <path d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" fill="#292f31"></path>
          </svg>
        </h3>
        <p class="text-sm col-span-12">Campos sobre o cartão de crédito</p>
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa de Venda (%)</label>
        <input type="number" v-model="contractForm.platformFeeCreditCard" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Recebimento em D+X</label>
        <input type="number" v-model="contractForm.payoutDaysCreditCard" placeholder="ex: 8" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa de Juros por Parcela (%)</label>
        <input type="number" v-model="contractForm.installmentsInterestFree" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Máximo de parcelas</label>
        <input type="number" v-model="contractForm.installmentsMax" placeholder="ex: 8" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa por parcela (%)</label>
        <input type="number" v-model="contractForm.installmentsTax" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm">Permissão de Venda - Cartão de Crédito</label>
        <MySwitch :id="'allowSaleCreditCard'" :checked="contractForm.allowSaleCreditCard!" :setChecked="(v) => contractForm.allowSaleCreditCard = v" />
      </div>
    </div>
    <div class="grid grid-cols-12 col-span-12 p-4 gap-6 border rounded-xl">
      <div class="col-span-12">
        <h3 class="text-1xl flex items-center gap-2 font-bold">
          Pix
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="#949494" class="pix">
            <g clip-path="url(#clip0_3119_239)">
              <path
                d="M17.9361 17.5931C17.4925 17.5946 17.053 17.5083 16.643 17.339C16.233 17.1697 15.8606 16.9209 15.5474 16.6069L12.0968 13.1545C11.9748 13.0378 11.8124 12.9727 11.6436 12.9727C11.4747 12.9727 11.3124 13.0378 11.1904 13.1545L7.72856 16.6163C7.41544 16.9307 7.04313 17.1799 6.63313 17.3496C6.22314 17.5193 5.78359 17.6061 5.33987 17.6049H4.66406L9.03643 21.9773C9.69218 22.6315 10.5807 22.999 11.507 22.999C12.4333 22.999 13.3218 22.6315 13.9776 21.9773L18.3588 17.5954L17.9361 17.5931Z"
              ></path>
              <path
                d="M5.34046 5.39248C5.78419 5.39126 6.22376 5.47799 6.63376 5.64768C7.04376 5.81736 7.41607 6.06663 7.72915 6.38107L11.191 9.84408C11.3113 9.96398 11.4743 10.0313 11.6442 10.0313C11.8141 10.0313 11.977 9.96398 12.0974 9.84408L15.5468 6.39467C15.8596 6.07975 16.2318 5.83009 16.6419 5.66018C17.0519 5.49027 17.4916 5.4035 17.9355 5.4049H18.3494L13.9681 1.02366C13.6437 0.699132 13.2586 0.441691 12.8347 0.266048C12.4108 0.0904048 11.9564 0 11.4975 0C11.0387 0 10.5843 0.0904048 10.1604 0.266048C9.73651 0.441691 9.35136 0.699132 9.02697 1.02366L4.66406 5.39248H5.34046Z"
              ></path>
              <path
                d="M21.9765 9.03094L19.3289 6.38328C19.2692 6.40768 19.2054 6.42052 19.1409 6.42112H17.9376C17.3112 6.4227 16.7107 6.67152 16.2667 7.11348L12.8167 10.5641C12.663 10.7178 12.4805 10.8398 12.2796 10.923C12.0788 11.0062 11.8635 11.049 11.6461 11.049C11.4286 11.049 11.2133 11.0062 11.0125 10.923C10.8116 10.8398 10.6291 10.7178 10.4754 10.5641L7.01235 7.10343C6.56848 6.66125 5.96798 6.41221 5.34145 6.41047H3.8633C3.80255 6.40913 3.74251 6.39713 3.68592 6.375L1.02171 9.03567C0.367439 9.69142 0 10.5799 0 11.5062C0 12.4326 0.367439 13.3211 1.02171 13.9768L3.68238 16.6375C3.73885 16.615 3.79896 16.6029 3.85975 16.602H5.34204C5.96856 16.6002 6.56904 16.3512 7.01294 15.9091L10.4748 12.4472C10.7904 12.1463 11.2097 11.9785 11.6458 11.9785C12.0818 11.9785 12.5011 12.1463 12.8167 12.4472L16.2662 15.8961C16.7101 16.338 17.3106 16.5868 17.9371 16.5884H19.1409C19.2054 16.5888 19.2692 16.6017 19.3289 16.6263L21.9765 13.9786C22.3011 13.6542 22.5585 13.2691 22.7342 12.8451C22.9098 12.4212 23.0002 11.9669 23.0002 11.508C23.0002 11.0492 22.9098 10.5948 22.7342 10.1709C22.5585 9.74699 22.3011 9.36183 21.9765 9.03744"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_3119_239">
                <rect width="23" height="23" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
        </h3>
        <p class="text-sm">Campos sobre PIX</p>
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa de Venda (%)</label>
        <input type="number" v-model="contractForm.platformFeePIX" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Recebimento em D+X</label>
        <input type="number" v-model="contractForm.payoutDaysPIX" placeholder="ex: 8" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm">Permissão de Venda - PIX</label>
        <MySwitch :id="'allowSalePIX'" :checked="contractForm.allowSalePIX!" :setChecked="(v) => contractForm.allowSalePIX = v" />
      </div>
    </div>
    <div class="grid grid-cols-12 col-span-12 p-4 gap-6 border rounded-xl">
      <div class="col-span-12">
        <h3 class="text-1xl flex items-center gap-2 font-bold">
          Boleto
          <svg class="fill-292" viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
            <path
              d="M20 5L20 19L4 19L4 5H20M20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3M18 15H6V17H18V15M10 7H6V13H10V7M12 9H18V7H12V9M18 11H12V13H18V11Z"
            ></path>
          </svg>
        </h3>
        <p class="text-sm col-span-12">Campos sobre boleto</p>
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Taxa de Venda (%)</label>
        <input type="number" v-model="contractForm.platformFeeBillet" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
        <label for="" class="font-semibold text-sm input-primary">Recebimento em D+X</label>
        <input type="number" v-model="contractForm.payoutDaysBillet" placeholder="ex: 8" class="border border-d5 p-3 rounded-xl w-full input-primary" />
      </div>
      <div class="flex flex-col gap-2 col-span-12 sm:col-span-3">
        <label for="" class="font-semibold text-sm">Permissão de Venda - Boleto</label>
        <MySwitch :id="'allowSaleBillet'" :checked="contractForm.allowSaleBillet!" :setChecked="(v) => contractForm.allowSaleBillet = v" />
      </div>
    </div>
  </div>
  <div class="grid grid-cols-12 col-span-12 p-4 gap-6 border rounded-xl">
    <div class="col-span-12">
      <h3 class="text-1xl flex items-center gap-2 font-bold">
        Indicado
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M18.1401 21.62C17.2601 21.88 16.2201 22 15.0001 22H9.00011C7.78011 22 6.74011 21.88 5.86011 21.62C6.08011 19.02 8.75011 16.97 12.0001 16.97C15.2501 16.97 17.9201 19.02 18.1401 21.62Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15 2H9C4 2 2 4 2 9V15C2 18.78 3.14 20.85 5.86 21.62C6.08 19.02 8.75 16.97 12 16.97C15.25 16.97 17.92 19.02 18.14 21.62C20.86 20.85 22 18.78 22 15V9C22 4 20 2 15 2ZM12 14.17C10.02 14.17 8.42 12.56 8.42 10.58C8.42 8.60002 10.02 7 12 7C13.98 7 15.58 8.60002 15.58 10.58C15.58 12.56 13.98 14.17 12 14.17Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.5799 10.58C15.5799 12.56 13.9799 14.17 11.9999 14.17C10.0199 14.17 8.41992 12.56 8.41992 10.58C8.41992 8.60002 10.0199 7 11.9999 7C13.9799 7 15.5799 8.60002 15.5799 10.58Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </h3>
      <p class="text-sm col-span-12">Configure as taxas de indicado</p>
    </div>
    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
      <label for="" class="font-semibold text-sm input-primary">Taxa no cartão (%)</label>
      <input type="number" v-model="contractForm.indicatedFeeCreditCard" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
    </div>
    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
      <label for="" class="font-semibold text-sm input-primary">Taxa no PIX (%)</label>
      <input type="number" v-model="contractForm.indicatedFeePIX" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
    </div>
    <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
      <label for="" class="font-semibold text-sm input-primary">Taxa no boleto (%)</label>
      <input type="number" v-model="contractForm.indicatedFeeBillet" placeholder="ex: 10" class="border border-d5 p-3 rounded-xl w-full input-primary" />
    </div>
  </div>
  <nav class="fixed">
    <input type="button" value="Enviar" @click="updateContract()" />
    <input type="button" value="Cancelar" @click="$router.push({ name: 'organizations' })" />
  </nav>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import axiosClient from '@/api/axios'
import MySwitch from '@/components/inputs/MySwitch.vue'
import IContract from '@/interfaces/organization/IContract'
import { useToast } from 'vue-toastification'

const props = defineProps<{ organizationId: number }>()
const contractForm = ref<Partial<IContract>>({})
const toast = useToast()

function getContracts () {
  axiosClient.get(`/organizations/${props.organizationId}/contracts`).then((res) => {
    console.log(res)
    contractForm.value = res.data[0]
  })
}

function updateContract () {
  const allowedFields = [
    'contractUrl', 'contractExternalId', 'interestCommission', 'anticipationFee',
    'platformFeeCreditCard', 'installmentsInterestFree', 'installmentsMax',
    'payoutDaysCreditCard', 'payoutWithdrawInterval', 'antiFraud', 'antiFraudFee',
    'automaticTransfer', 'payoutDaysPIX', 'payoutDaysBillet', 'platformFeePIX',
    'platformFeeBillet', 'platformCostWithdraw', 'allowSaleCreditCard',
    'allowSaleBillet', 'allowSalePIX', 'installmentsTax', 'indicatedFeeCreditCard',
    'indicatedFeePIX', 'indicatedFeeBillet', 'plataformFeeTypePIX', 'plataformFeeTypeBillet'
  ]

  const filteredData = Object.fromEntries(
    Object.entries(contractForm.value)
      .filter(([key, value]) => allowedFields.includes(key) && value !== null)
  )

  axiosClient.put(
      `/organizations/${props.organizationId}/contracts/${contractForm.value.id}`,
      filteredData
  )
    .then(() => {
      toast.success('Contrato editado com sucesso')
    })
}

onMounted(() => {
  getContracts()
})
</script>

<style lang="scss"></style>
