<template>
  <div class="font-inter flex flex-col gap-8 withdrawals">
    <div>
      <h1 class="text-2xl font-bold pb-2 text-292 font-nunito font-bold">Gerenciar saques</h1>
      <p class="text-gray-500 text-sm">Adicione, edite e exclua saques</p>
    </div>
    <div class="grid grid-cols-12 organizations__filter gap-4">
      <form @submit.prevent class="rounded-xl w-full relative col-span-10 organizations__filter__search">
        <input
          v-model="filter.name"
          autocomplete="off"
          type="text"
          placeholder="Busque pelo nome da organização"
          class="rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11"
          @input="handleSearch"
        />
        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z"
            fill="#676767"
          />
        </svg>
      </form>
      <div class="col-span-1">
        <button class="p-3 border border-f5 rounded-xl" @click="isFilterOpen = true">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5.3999 2.1001H18.5999C19.6999 2.1001 20.5999 3.0001 20.5999 4.1001V6.3001C20.5999 7.1001 20.0999 8.1001 19.5999 8.6001L15.2999 12.4001C14.6999 12.9001 14.2999 13.9001 14.2999 14.7001V19.0001C14.2999 19.6001 13.8999 20.4001 13.3999 20.7001L11.9999 21.6001C10.6999 22.4001 8.8999 21.5001 8.8999 19.9001V14.6001C8.8999 13.9001 8.4999 13.0001 8.0999 12.5001L4.2999 8.5001C3.7999 8.0001 3.3999 7.1001 3.3999 6.5001V4.2001C3.3999 3.0001 4.2999 2.1001 5.3999 2.1001Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.93 2.1001L6 10.0001" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
    <table class="w-full">
      <thead class="grid grid-cols-12 p-4">
        <div class="col-span-2">Organização</div>
        <div class="col-span-1">Banco</div>
        <div class="col-span-1">Agência/Conta</div>
        <div class="col-span-1">Tipo</div>
        <div class="col-span-1">Transferência automatica</div>
        <div class="col-span-1">Valor</div>
        <div class="col-span-1">Status</div>
        <div class="col-span-2">Mensagem</div>
        <div class="col-span-2">Ações</div>
      </thead>
      <tbody v-if="isLoading" class="flex flex-col gap-4">
        <tr v-for="i in 5" :key="i" class="grid grid-cols-12 border rounded-xl p-4 product-tr">
          <td v-for="j in 9" :key="j" :class="['skeleton-loading', getSkeletonClass(j)]"></td>
        </tr>
      </tbody>
      <tbody v-else class="flex flex-col gap-4">
        <tr v-for="(liquidations, index) in liquidations?.data" class="grid grid-cols-12 border rounded-xl p-4 product-tr">
          <td class="col-span-2">
            <RouterLink
              :to="{
                name: 'organizations.edit',
                params: { organizationId: liquidations.organizationId },
              }"
              class="hover:text-primary transition-color duration-200"
            >
              {{ liquidations.organizationName }}
            </RouterLink>
          </td>
          <td class="col-span-1">{{ liquidations.bankName || '-' }}</td>
          <td class="col-span-1">
            <template v-if="liquidations.bankAccountAgency && liquidations.bankAccountNumber">
              {{ `${liquidations.bankAccountAgency}/${liquidations.bankAccountNumber}` }}
            </template>
            <span v-else>-</span>
          </td>
          <td class="col-span-1">{{ liquidations.type || '-' }}</td>
          <td class="col-span-1">
            <template v-if="liquidations.transferAutomatic !== null">
              <span
                v-if="liquidations.transferAutomatic"
                class="inline-flex items-center justify-center w-6 h-6 bg-green-100 rounded-full"
              >
                <span class="text-green-500">✓</span>
              </span>
              <span
                v-else
                class="inline-flex items-center justify-center w-6 h-6 bg-red-100 rounded-full"
              >
                <span class="text-red-500">✗</span>
              </span>
            </template>
            <span v-else>-</span>
          </td>
          <td class="col-span-1">{{
            liquidations.amount !== null ?
              liquidations.amount.toLocaleString(
                'pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  }
                )
              : '-'
            }}
          </td>
            <td class="col-span-1 sm:col-span-1 tag flex flex-col gap-2" :class="'text-' + withdrawalsStatusDetails[liquidations.status].color">
            <div class="inline-flex">
              <div
              class="badge inline-flex"
              :class="'bg-' + withdrawalsStatusDetails[liquidations.status].color"
              >
              {{ withdrawalsStatusDetails[liquidations.status].name || '-' }}
              </div>
            </div>
            </td>
            <td class="col-span-2">{{ liquidations.failMessage || '-' }}</td>
            <td class="col-span-2">
            <div v-if="liquidations.status === 'pending' && !liquidations.transferAutomatic" class="flex items-center gap-2">
              <button class="bg-red-600 p-2 rounded-lg text-white" @click="openRejectModal(liquidations.id)">Recusar</button>
              <button class="bg-emerald-600 p-2 rounded-lg text-white" @click="openApproveModal(liquidations.id)">Aprovar</button>
            </div>
            <span v-else>-</span>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination v-if="liquidations?.data?.length" :data="liquidations?.pagination" :paginate="getWithdrawals" />
    <MyModal :width="'330px !important'" :height="'270px !important'" :isOpen="isApproveWithdrawalModalOpen || isRejectWithdrawalModalOpen" :toggleModal="closeModals">
      <div class="p-4 confirm-withdrawal-approval">
      <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 24 24" fill="none">
        <path v-if="isApproveWithdrawalModalOpen" d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#079f72"/>
        <path v-else d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#A20711"/>
      </svg>
      <h3>Atenção</h3>
      <p class="pt-4">
        {{ isApproveWithdrawalModalOpen ? 'Tem certeza que deseja aprovar o saque?' : 'Tem certeza que deseja reprovar o saque?' }}
      </p>
      <div class="flex justify-between mt-8 confirm-withdrawal-approval__btns items-center">
        <button class="confirm-button" @click="handleWithdrawalUpdate(isApproveWithdrawalModalOpen ? 'success' : 'fail')">
        Sim
        </button>
        <button class="cancel-button" @click="closeModals">Cancelar</button>
      </div>
      </div>
    </MyModal>
    <div class="filter-sidebar bg-white fixed bottom-0 h-full md:1/2 lg:w-1/3 box-shadow font-inter p-6 transition-all duration-200 z-30 transform right-0" :class="isFilterOpen ? 'translate-x-0' : 'translate-x-[100%]'">
      <div class="flex justify-between mt-4">
        <h2 class="font-nunito font-bold text-2xl">Filtros</h2>
        <button class="transition duration-200 hover:text-primary" @click="isFilterOpen = false">
          <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor"></path>
          </svg>
        </button>
      </div>
      <section class="flex flex-col gap-4">
        <h3>Filtrar pos status</h3>
        <div class="flex gap-10">
          <label :for="status" v-for="status in StatusWithdrawalsEnum">
            <input type="radio" name="by-stats" :id="status" :value="status" v-model="filter.status">
            <span></span>
            <p>{{ status === 'in progress' ? 'in_progress' : StatusWithdrawalsEnum[status] }}</p>
          </label>
        </div>
      </section>
      <div class="action">
        <input type="button" value="Filtrar" @click="getWithdrawals(); isFilterOpen = false;">
        <input type="button" value="Limpar" @click="filter = {}; getWithdrawals(); isFilterOpen = false;">
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref, onUnmounted } from 'vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'
  import { StatusWithdrawalsEnum } from '@/enums/withdrawals/WithdrawalsEnum'
  import Pagination from '@/components/pagination/Pagination.vue'
  import { useRoute } from 'vue-router';
  import IPagination from '@/interfaces/pagination/IPagination'
  import IWithdrawals from '@/interfaces/withdrawals/IWithdrawals'
  import { withdrawalsStatusDetails } from '@/enums/withdrawals/WithdrawalsEnum'

  const liquidationId = ref<number | null>(null)
  const route = useRoute()
  const isApproveWithdrawalModalOpen = ref(false)
  const isRejectWithdrawalModalOpen = ref(false)
  const liquidations = ref<IPagination<IWithdrawals>>()
  const filter = ref<Partial<{ status?: string | null, name?: string | null }>>({ status: route.query.status as string })
  const isFilterOpen = ref(false)
  const isLoading = ref(false)
  let searchTimeout: number | undefined

  function openApproveModal(id: number) {
    liquidationId.value = id
    isApproveWithdrawalModalOpen.value = true
  }

  function openRejectModal(id: number) {
    liquidationId.value = id
    isRejectWithdrawalModalOpen.value = true
  }

  function closeModals() {
    isApproveWithdrawalModalOpen.value = false
    isRejectWithdrawalModalOpen.value = false
    liquidationId.value = null
  }

  function getSkeletonClass(column: number) {
    const classes = {
      1: 'col-span-2 h-8',
      2: 'col-span-1 h-8',
      3: 'col-span-1 h-8',
      4: 'col-span-1 h-8',
      5: 'col-span-1 h-8',
      6: 'col-span-1 h-8',
      7: 'col-span-1 h-8',
      8: 'col-span-2 h-8',
      9: 'col-span-2 h-8'
    }[column] || ''
    return classes
  }

  function handleSearch() {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    isLoading.value = true

    searchTimeout = setTimeout(() => {
      getWithdrawals()
    }, 800)
  }

  async function getWithdrawals(page?: number) {
    try {
      const params: any = {
        ...(filter.value.name && { name: filter.value.name }),
        ...(filter.value.status && { status: filter.value.status }),
        ...(page && { page })
      }

      const response = await axiosClient.get('/administrator/finance/liquidations', { params })
      liquidations.value = response.data
    } catch (error) {
      console.error('Erro ao buscar saques:', error)
    } finally {
      isLoading.value = false
    }
  }

  async function handleWithdrawalUpdate(status: 'success' | 'fail') {
    try {
      console.log(status)
      await axiosClient.put(`/administrator/finance/liquidations/${liquidationId.value}`, {
        status
      })

      getWithdrawals()
      isApproveWithdrawalModalOpen.value = false
      isRejectWithdrawalModalOpen.value = false
    } catch (error) {
      console.error('Error updating withdrawal:', error)
    }
  }

  onMounted(() => {
    getWithdrawals()
    console.log(liquidations)
  })

  onUnmounted(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
  })
</script>

<style lang="scss" scoped>
  .withdrawals {
    input[type='text'] {
      width: 100%;
      height: 55px;
      border: none;
      border-radius: 16px;
      background: #f5f5f5;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      transition: background 0.2s;
      &:focus {
        border: none !important;
        outline: none !important;
      }
      &:focus::placeholder {
        color: transparent;
        border: none !important;
      }
    }
    .confirm-withdrawal-approval {
      h3 {
        color: #090909;
        font-family: Nunito, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        text-align: center;
      }
      p {
        color: #6f6f6f;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 15px;
      }
      &__btns {
        display: flex;
        gap: 16px;

        button {
          height: 60px;
          width: 130px;
          font-family: 'Inter', sans-serif;
          font-size: 13px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          outline: none;

          &.confirm-button {
            background: #090909;
            color: #FFF;

            &:hover {
              background: darken(#090909, 10%);
              transform: translateY(-2px);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }

            &:active {
              transform: translateY(0);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }
          }

          &.cancel-button {
            background: transparent;
            color: #090909;
            border: 2px solid #090909;

            &:hover {
              background: rgba(#090909, 0.1);
              color: darken(#090909, 10%);
              transform: translateY(-2px);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }

            &:active {
              transform: translateY(0);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
    .filter-sidebar {
      max-width: 554px;
      h3 {
        color: #575757;
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 53px 0 0 0;
      }
      label{
        display: flex;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;
        input{
          display: none;
          &:checked{
            & ~ span{
              border-color: $primary-color;
              outline: 1px solid $primary-color;
              &::before{
                transform: scale(1.0);
                opacity: 1;
              }
            }
            & ~ p{
              color: $primary-color;
            }
          }
        }
        span{
          width: 24px;
          min-width: 24px;
          height: 24px;
          min-height: 24px;
          border-radius: 50%;
          border: 1px solid #D5D5D5;
          position: relative;
          transition: border-color .2s;
          box-sizing: unset;
          &::before{
            content: '';
            width: 12px;
            min-width: 12px;
            height: 12px;
            min-height: 12px;
            border-radius: 50%;
            background: $primary-color;
            position: absolute;
            top: 6px;
            left: 6px;
            transform: scale(0.0);
            opacity: 0;
            transition: transform .2s, opacity .1s;
          }
        }
        p{
          color: #949494;
          font-family: 'Inter', sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
          margin: 0;
          transition: color .2s;
        }
      }
      .action{
        width: 100%;
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 32px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        position: fixed;
        right: 0;
        bottom: 0;
        column-gap: 20px;
        input{
          border: none;
          cursor: pointer;
          &:first-child{
            width: 100%;
            max-width: 220px;
            height: 60px;
            border-radius: 30px;
            background: $primary-color;
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            transition: background .2s;
            &:hover{
              background: $primary-color-hover;
            }
          }
          &:last-child{
            color: #737373;
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            background: unset;
            transition: color .2s;
            &:hover{
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .withdrawals {
      .withdrawal-thead {
        display: none;
      }
      .withdrawal-tr {
        grid-template-columns: 1fr;
      }
      &__filter {
        grid-template-columns: 1fr;
      }
      #td-contact {
        padding-bottom: 1rem;
      }
    }
  }

  .skeleton-loading {
    background: linear-gradient(
      90deg,
      rgba(245, 245, 245, 0.6) 25%,
      rgba(235, 235, 235, 0.8) 37%,
      rgba(245, 245, 245, 0.6) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
    border-radius: 4px;
    margin: 4px;
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
</style>
