export enum MovementsTypeTransactionsEnum {
  ted = 'ted',
  pix = 'pix',
  billet = 'billet',
  anticipation = 'anticipation',
  credit_adjustment = 'credit_adjustment',
  debit_adjustment = 'debit_adjustment',
  withdraw = 'withdraw',
  tax = 'tax',
  purchase = 'purchase',
  deposit = 'deposit',
  payment = 'payment',
  transfer = 'transfer',
  commission = 'commission',
}

export const MovementsTypeTransactions = {
  [MovementsTypeTransactionsEnum.ted]: 'TED',
  [MovementsTypeTransactionsEnum.pix]: 'PIX',
  [MovementsTypeTransactionsEnum.billet]: 'Pgto Boleto',
  [MovementsTypeTransactionsEnum.anticipation]: 'Antecipação',
  [MovementsTypeTransactionsEnum.credit_adjustment]: 'Ajuste de Crédito',
  [MovementsTypeTransactionsEnum.debit_adjustment]: 'Ajuste de Débito',
  [MovementsTypeTransactionsEnum.withdraw]: 'Transferência',
  [MovementsTypeTransactionsEnum.tax]: 'Taxa',
  [MovementsTypeTransactionsEnum.purchase]: 'Compra',
  [MovementsTypeTransactionsEnum.deposit]: 'Depósito',
  [MovementsTypeTransactionsEnum.payment]: 'Pagamento',
  [MovementsTypeTransactionsEnum.transfer]: 'Transferência',
  [MovementsTypeTransactionsEnum.commission]: 'Comissão',
};

export default MovementsTypeTransactionsEnum;