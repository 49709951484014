import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "checked"]
const _hoisted_2 = ["for"]




export default /*@__PURE__*/_defineComponent({
  __name: 'MySwitch',
  props: {
    id: {},
    checked: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

function onChange (e) {
  const checked = (e.target as HTMLInputElement).checked
  emit('update:modelValue', checked)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      class: "hidden chk",
      type: "checkbox",
      id: _ctx.id,
      checked: _ctx.checked,
      onChange: onChange
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "switch"
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("span", { class: "slider" }, null, -1)
    ]), 8, _hoisted_2)
  ]))
}
}

})