import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, unref as _unref, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "font-inter flex flex-col gap-8 organizations" }
const _hoisted_2 = { class: "grid grid-cols-12 organizations__filter gap-4" }
const _hoisted_3 = { class: "col-span-1" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "flex flex-col gap-4 text-xs" }
const _hoisted_6 = { class: "organization-tr border rounded-xl p-4" }
const _hoisted_7 = { class: "flex items-center font-bold flex flex-wrap" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "" }
const _hoisted_11 = {
  class: "flex flex-col",
  id: "td-contact"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "rounded-xl flex items-center gap-2 font-semibold" }
const _hoisted_14 = { class: "flex items-center" }
const _hoisted_15 = { class: "flex justify-between mt-4" }
const _hoisted_16 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_17 = { class: "flex flex-col gap-4" }
const _hoisted_18 = { class: "flex gap-10" }
const _hoisted_19 = ["for"]
const _hoisted_20 = ["id", "value", "onUpdate:modelValue"]
const _hoisted_21 = { class: "action" }

import { onMounted, ref } from 'vue'
import axiosClient from '@/api/axios'
import Pagination from '@/components/pagination/Pagination.vue'
import OrganizationStatusEnum, { organizationStatusTranslations } from '@/enums/organization/OrganizationStatusEnum'
import { useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganizationsView',
  setup(__props) {

const route = useRoute()
const organizations = ref<any>([])
const filter = ref<Partial<{ status?: string | null }>>({ status: route.query.status as string })
const isFilterOpen = ref(false)

function getOrganizations (page?: number) {
  const params: any = { ...filter.value }

  if (page) {
    params.page = page
  }

  axiosClient.get('/administrator/organizations', { params }).then((res: any) => {
    organizations.value = res.data
  })
}

function formatDocument (document: string): string {
  if (document.length === 11) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (document.length === 14) {
    return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  return document
}

onMounted(() => {
  getOrganizations()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[16] || (_cache[16] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "text-2xl font-bold pb-2 text-md font-nunito font-bold" }, "Gerenciar organizações"),
      _createElementVNode("p", { class: "text-gray-500 text-sm" }, "Adicione, edite e exclua organizações")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        class: "rounded-xl w-full relative col-span-10 organizations__filter__search"
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("input", {
          autocomplete: "off",
          type: "text",
          placeholder: "Busque pelo nome da organização",
          class: "rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11"
        }, null, -1),
        _createElementVNode("svg", {
          class: "absolute top-1/2 -translate-y-1/2 left-4",
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
            fill: "#676767"
          })
        ], -1)
      ]), 32),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "p-3 border border-f5 rounded-xl",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (isFilterOpen.value = true))
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M5.3999 2.1001H18.5999C19.6999 2.1001 20.5999 3.0001 20.5999 4.1001V6.3001C20.5999 7.1001 20.0999 8.1001 19.5999 8.6001L15.2999 12.4001C14.6999 12.9001 14.2999 13.9001 14.2999 14.7001V19.0001C14.2999 19.6001 13.8999 20.4001 13.3999 20.7001L11.9999 21.6001C10.6999 22.4001 8.8999 21.5001 8.8999 19.9001V14.6001C8.8999 13.9001 8.4999 13.0001 8.0999 12.5001L4.2999 8.5001C3.7999 8.0001 3.3999 7.1001 3.3999 6.5001V4.2001C3.3999 3.0001 4.2999 2.1001 5.3999 2.1001Z",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-miterlimit": "10",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }),
            _createElementVNode("path", {
              d: "M10.93 2.1001L6 10.0001",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-miterlimit": "10",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            })
          ], -1)
        ]))
      ])
    ]),
    _createElementVNode("table", _hoisted_4, [
      _cache[11] || (_cache[11] = _createElementVNode("thead", { class: "organization-thead p-4 text-292" }, [
        _createElementVNode("div", { class: "" }, "Razão Social"),
        _createElementVNode("div", { class: "" }, "Documento"),
        _createElementVNode("div", { class: "" }, "Contato"),
        _createElementVNode("div", { class: "" }, "Saúde"),
        _createElementVNode("div", { class: "" }, "Status"),
        _createElementVNode("div", { class: "" }, "Criado/Atualizado"),
        _createElementVNode("div", { class: "" }, "Ações")
      ], -1)),
      _createElementVNode("tbody", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organizations.value.data, (organization, index) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("td", _hoisted_7, [
              _createVNode(_component_RouterLink, {
                to: {
                name: 'organizations.edit',
                params: { organizationId: organization.id },
              },
                class: "hover:text-primary transition-color duration-200"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(organization.name), 1),
                  (organization.nameFantasy)
                    ? (_openBlock(), _createElementBlock("br", _hoisted_8))
                    : _createCommentVNode("", true),
                  (organization.nameFantasy)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(organization.nameFantasy), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("td", _hoisted_10, _toDisplayString(formatDocument(organization.document)), 1),
            _createElementVNode("td", _hoisted_11, [
              _createTextVNode(_toDisplayString(organization.contactEmail) + " ", 1),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("a", {
                class: "text-greenbase text-underline",
                href: `https://wa.me/${organization.contactPhone}`,
                target: "_blank"
              }, [
                _createElementVNode("u", null, _toDisplayString(organization.contactPhone), 1)
              ], 8, _hoisted_12)
            ]),
            _createElementVNode("td", _hoisted_13, [
              _createElementVNode("span", {
                class: _normalizeClass(["badge", {
              'bg-success' : (organization.health?.totalPercentage >= 98),
              'bg-warning': (organization.health?.totalPercentage >= 96 && organization.health?.totalPercentage < 98),
              'bg-orange': (organization.health?.totalPercentage >= 94 && organization.health?.totalPercentage < 96),
              'bg-danger': (organization.health?.totalPercentage < 94),
            }])
              }, _toDisplayString(organization.health?.totalPercentage) + "%", 3)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center badge inline-flex", {
              'bg-success': organization.status == 'active',
              'bg-info': organization.status == 'pending',
              'bg-danger': organization.status == 'blocked',
             }])
              }, _toDisplayString(organization.status == 'active' ? 'ativo' : (organization.status == 'pending' ? 'pendente' : 'bloqueado')), 3)
            ]),
            _createElementVNode("td", null, [
              _createTextVNode(_toDisplayString(new Date(organization.createdAt).toLocaleString('pt-BR')) + " ", 1),
              _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(new Date(organization.updatedAt).toLocaleString('pt-BR')), 1)
            ]),
            _createElementVNode("td", _hoisted_14, [
              _createVNode(_component_RouterLink, {
                class: "hover:stroke-primary",
                to: {
                name: 'organizations.edit',
                params: { organizationId: organization.id },
              }
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "25",
                    height: "24",
                    viewBox: "0 0 25 24",
                    fill: "none"
                  }, [
                    _createElementVNode("path", {
                      d: "M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary transition-all duration-200"
                    }),
                    _createElementVNode("path", {
                      d: "M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary transition-all duration-200"
                    }),
                    _createElementVNode("path", {
                      d: "M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary transition-all duration-200"
                    })
                  ], -1)
                ])),
                _: 2
              }, 1032, ["to"])
            ])
          ]))
        }), 256))
      ])
    ]),
    (organizations.value.data?.length)
      ? (_openBlock(), _createBlock(Pagination, {
          key: 0,
          data: organizations.value.pagination,
          paginate: getOrganizations
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    (isFilterOpen.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "fixed top-0 left-0 w-full bottom-0 h-full bg-black bg-opacity-30",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (isFilterOpen.value = false))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["filter-sidebar bg-white fixed bottom-0 h-full md:1/2 lg:w-1/3 box-shadow font-inter p-6 transition-all duration-200 z-30 transform right-0", isFilterOpen.value ? 'translate-x-0' : 'translate-x-[100%]'])
    }, [
      _createElementVNode("div", _hoisted_15, [
        _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "font-nunito font-bold text-2xl" }, "Filtros", -1)),
        _createElementVNode("button", {
          class: "transition duration-200 hover:text-primary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (isFilterOpen.value = false))
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_16, _cache[12] || (_cache[12] = [
            _createElementVNode("path", {
              d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
              style: {"fill":"currentcolor"}
            }, null, -1)
          ])))
        ])
      ]),
      _createElementVNode("section", _hoisted_17, [
        _cache[15] || (_cache[15] = _createElementVNode("h3", null, "Filtrar pos status", -1)),
        _createElementVNode("div", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(OrganizationStatusEnum), (status) => {
            return (_openBlock(), _createElementBlock("label", { for: status }, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                name: "by-stats",
                id: status,
                value: status,
                "onUpdate:modelValue": ($event: any) => ((filter.value.status) = $event)
              }, null, 8, _hoisted_20), [
                [_vModelRadio, filter.value.status]
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("span", null, null, -1)),
              _createElementVNode("p", null, _toDisplayString(_unref(organizationStatusTranslations)[status]), 1)
            ], 8, _hoisted_19))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("input", {
          type: "button",
          value: "Filtrar",
          onClick: _cache[4] || (_cache[4] = ($event: any) => {getOrganizations(); isFilterOpen.value = false;})
        }),
        _createElementVNode("input", {
          type: "button",
          value: "Limpar",
          onClick: _cache[5] || (_cache[5] = ($event: any) => {filter.value = {}; getOrganizations(); isFilterOpen.value = false;})
        })
      ])
    ], 2)
  ]))
}
}

})