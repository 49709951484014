export enum StatusWithdrawalsEnum {
  pending = 'pending',
  in_progress = 'in progress',
  fail = 'fail',
  success = 'success'
}

interface WithdrawalsStatusDetail {
  name: string
  tag: string
  color: string
}
export const withdrawalsStatusDetails: {
  [key in StatusWithdrawalsEnum]: WithdrawalsStatusDetail
} = {
  [StatusWithdrawalsEnum.pending]: {
    name: 'Pendente',
    tag: 'pending',
    color: 'warning',
  },
  [StatusWithdrawalsEnum.in_progress]: {
    name: 'Em progresso',
    tag: 'in_progress',
    color: 'primary',
  },
  [StatusWithdrawalsEnum.fail]: {
    name: 'Falha',
    tag: 'fail',
    color: 'danger',
  },
  [StatusWithdrawalsEnum.success]: {
    name: 'Sucesso',
    tag: 'success',
    color: 'success',
  },
}
