import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createBlock as _createBlock, vModelRadio as _vModelRadio } from "vue"

const _hoisted_1 = { class: "font-inter flex flex-col gap-8 withdrawals" }
const _hoisted_2 = { class: "grid grid-cols-12 organizations__filter gap-4" }
const _hoisted_3 = { class: "col-span-1" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col gap-4"
}
const _hoisted_6 = {
  key: 1,
  class: "flex flex-col gap-4"
}
const _hoisted_7 = { class: "grid grid-cols-12 border rounded-xl p-4 product-tr" }
const _hoisted_8 = { class: "col-span-2" }
const _hoisted_9 = { class: "col-span-1" }
const _hoisted_10 = { class: "col-span-1" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "col-span-1" }
const _hoisted_13 = { class: "col-span-1" }
const _hoisted_14 = {
  key: 0,
  class: "inline-flex items-center justify-center w-6 h-6 bg-green-100 rounded-full"
}
const _hoisted_15 = {
  key: 1,
  class: "inline-flex items-center justify-center w-6 h-6 bg-red-100 rounded-full"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "col-span-1" }
const _hoisted_18 = { class: "inline-flex" }
const _hoisted_19 = { class: "col-span-2" }
const _hoisted_20 = { class: "col-span-2" }
const _hoisted_21 = {
  key: 0,
  class: "flex items-center gap-2"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "p-4 confirm-withdrawal-approval" }
const _hoisted_26 = {
  class: "mx-auto",
  xmlns: "http://www.w3.org/2000/svg",
  width: "53",
  height: "53",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_27 = {
  key: 0,
  d: "M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z",
  fill: "#079f72"
}
const _hoisted_28 = {
  key: 1,
  d: "M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z",
  fill: "#A20711"
}
const _hoisted_29 = { class: "pt-4" }
const _hoisted_30 = { class: "flex justify-between mt-8 confirm-withdrawal-approval__btns items-center" }
const _hoisted_31 = { class: "flex justify-between mt-4" }
const _hoisted_32 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_33 = { class: "flex flex-col gap-4" }
const _hoisted_34 = { class: "flex gap-10" }
const _hoisted_35 = ["for"]
const _hoisted_36 = ["id", "value", "onUpdate:modelValue"]
const _hoisted_37 = { class: "action" }

import { onMounted, ref, onUnmounted } from 'vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'
  import { StatusWithdrawalsEnum } from '@/enums/withdrawals/WithdrawalsEnum'
  import Pagination from '@/components/pagination/Pagination.vue'
  import { useRoute } from 'vue-router';
  import IPagination from '@/interfaces/pagination/IPagination'
  import IWithdrawals from '@/interfaces/withdrawals/IWithdrawals'
  import { withdrawalsStatusDetails } from '@/enums/withdrawals/WithdrawalsEnum'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'WithdrawalsView',
  setup(__props) {

  const liquidationId = ref<number | null>(null)
  const route = useRoute()
  const isApproveWithdrawalModalOpen = ref(false)
  const isRejectWithdrawalModalOpen = ref(false)
  const liquidations = ref<IPagination<IWithdrawals>>()
  const filter = ref<Partial<{ status?: string | null, name?: string | null }>>({ status: route.query.status as string })
  const isFilterOpen = ref(false)
  const isLoading = ref(false)
  let searchTimeout: number | undefined

  function openApproveModal(id: number) {
    liquidationId.value = id
    isApproveWithdrawalModalOpen.value = true
  }

  function openRejectModal(id: number) {
    liquidationId.value = id
    isRejectWithdrawalModalOpen.value = true
  }

  function closeModals() {
    isApproveWithdrawalModalOpen.value = false
    isRejectWithdrawalModalOpen.value = false
    liquidationId.value = null
  }

  function getSkeletonClass(column: number) {
    const classes = {
      1: 'col-span-2 h-8',
      2: 'col-span-1 h-8',
      3: 'col-span-1 h-8',
      4: 'col-span-1 h-8',
      5: 'col-span-1 h-8',
      6: 'col-span-1 h-8',
      7: 'col-span-1 h-8',
      8: 'col-span-2 h-8',
      9: 'col-span-2 h-8'
    }[column] || ''
    return classes
  }

  function handleSearch() {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    isLoading.value = true

    searchTimeout = setTimeout(() => {
      getWithdrawals()
    }, 800)
  }

  async function getWithdrawals(page?: number) {
    try {
      const params: any = {
        ...(filter.value.name && { name: filter.value.name }),
        ...(filter.value.status && { status: filter.value.status }),
        ...(page && { page })
      }

      const response = await axiosClient.get('/administrator/finance/liquidations', { params })
      liquidations.value = response.data
    } catch (error) {
      console.error('Erro ao buscar saques:', error)
    } finally {
      isLoading.value = false
    }
  }

  async function handleWithdrawalUpdate(status: 'success' | 'fail') {
    try {
      console.log(status)
      await axiosClient.put(`/administrator/finance/liquidations/${liquidationId.value}`, {
        status
      })

      getWithdrawals()
      isApproveWithdrawalModalOpen.value = false
      isRejectWithdrawalModalOpen.value = false
    } catch (error) {
      console.error('Error updating withdrawal:', error)
    }
  }

  onMounted(() => {
    getWithdrawals()
    console.log(liquidations)
  })

  onUnmounted(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
  })

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[17] || (_cache[17] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "text-2xl font-bold pb-2 text-292 font-nunito font-bold" }, "Gerenciar saques"),
      _createElementVNode("p", { class: "text-gray-500 text-sm" }, "Adicione, edite e exclua saques")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        class: "rounded-xl w-full relative col-span-10 organizations__filter__search"
      }, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter.value.name) = $event)),
          autocomplete: "off",
          type: "text",
          placeholder: "Busque pelo nome da organização",
          class: "rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11",
          onInput: handleSearch
        }, null, 544), [
          [_vModelText, filter.value.name]
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("svg", {
          class: "absolute top-1/2 -translate-y-1/2 left-4",
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
            fill: "#676767"
          })
        ], -1))
      ], 32),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "p-3 border border-f5 rounded-xl",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (isFilterOpen.value = true))
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M5.3999 2.1001H18.5999C19.6999 2.1001 20.5999 3.0001 20.5999 4.1001V6.3001C20.5999 7.1001 20.0999 8.1001 19.5999 8.6001L15.2999 12.4001C14.6999 12.9001 14.2999 13.9001 14.2999 14.7001V19.0001C14.2999 19.6001 13.8999 20.4001 13.3999 20.7001L11.9999 21.6001C10.6999 22.4001 8.8999 21.5001 8.8999 19.9001V14.6001C8.8999 13.9001 8.4999 13.0001 8.0999 12.5001L4.2999 8.5001C3.7999 8.0001 3.3999 7.1001 3.3999 6.5001V4.2001C3.3999 3.0001 4.2999 2.1001 5.3999 2.1001Z",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-miterlimit": "10",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }),
            _createElementVNode("path", {
              d: "M10.93 2.1001L6 10.0001",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-miterlimit": "10",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            })
          ], -1)
        ]))
      ])
    ]),
    _createElementVNode("table", _hoisted_4, [
      _cache[11] || (_cache[11] = _createElementVNode("thead", { class: "grid grid-cols-12 p-4" }, [
        _createElementVNode("div", { class: "col-span-2" }, "Organização"),
        _createElementVNode("div", { class: "col-span-1" }, "Banco"),
        _createElementVNode("div", { class: "col-span-1" }, "Agência/Conta"),
        _createElementVNode("div", { class: "col-span-1" }, "Tipo"),
        _createElementVNode("div", { class: "col-span-1" }, "Transferência automatica"),
        _createElementVNode("div", { class: "col-span-1" }, "Valor"),
        _createElementVNode("div", { class: "col-span-1" }, "Status"),
        _createElementVNode("div", { class: "col-span-2" }, "Mensagem"),
        _createElementVNode("div", { class: "col-span-2" }, "Ações")
      ], -1)),
      (isLoading.value)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_5, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
              return _createElementVNode("tr", {
                key: i,
                class: "grid grid-cols-12 border rounded-xl p-4 product-tr"
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (j) => {
                  return _createElementVNode("td", {
                    key: j,
                    class: _normalizeClass(['skeleton-loading', getSkeletonClass(j)])
                  }, null, 2)
                }), 64))
              ])
            }), 64))
          ]))
        : (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(liquidations.value?.data, (liquidations, index) => {
              return (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                _createElementVNode("td", _hoisted_8, [
                  _createVNode(_component_RouterLink, {
                    to: {
                name: 'organizations.edit',
                params: { organizationId: liquidations.organizationId },
              },
                    class: "hover:text-primary transition-color duration-200"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(liquidations.organizationName), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createElementVNode("td", _hoisted_9, _toDisplayString(liquidations.bankName || '-'), 1),
                _createElementVNode("td", _hoisted_10, [
                  (liquidations.bankAccountAgency && liquidations.bankAccountNumber)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(`${liquidations.bankAccountAgency}/${liquidations.bankAccountNumber}`), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock("span", _hoisted_11, "-"))
                ]),
                _createElementVNode("td", _hoisted_12, _toDisplayString(liquidations.type || '-'), 1),
                _createElementVNode("td", _hoisted_13, [
                  (liquidations.transferAutomatic !== null)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (liquidations.transferAutomatic)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _cache[9] || (_cache[9] = [
                              _createElementVNode("span", { class: "text-green-500" }, "✓", -1)
                            ])))
                          : (_openBlock(), _createElementBlock("span", _hoisted_15, _cache[10] || (_cache[10] = [
                              _createElementVNode("span", { class: "text-red-500" }, "✗", -1)
                            ])))
                      ], 64))
                    : (_openBlock(), _createElementBlock("span", _hoisted_16, "-"))
                ]),
                _createElementVNode("td", _hoisted_17, _toDisplayString(liquidations.amount !== null ?
              liquidations.amount.toLocaleString(
                'pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  }
                )
              : '-'), 1),
                _createElementVNode("td", {
                  class: _normalizeClass(["col-span-1 sm:col-span-1 tag flex flex-col gap-2", 'text-' + _unref(withdrawalsStatusDetails)[liquidations.status].color])
                }, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["badge inline-flex", 'bg-' + _unref(withdrawalsStatusDetails)[liquidations.status].color])
                    }, _toDisplayString(_unref(withdrawalsStatusDetails)[liquidations.status].name || '-'), 3)
                  ])
                ], 2),
                _createElementVNode("td", _hoisted_19, _toDisplayString(liquidations.failMessage || '-'), 1),
                _createElementVNode("td", _hoisted_20, [
                  (liquidations.status === 'pending' && !liquidations.transferAutomatic)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                        _createElementVNode("button", {
                          class: "bg-red-600 p-2 rounded-lg text-white",
                          onClick: ($event: any) => (openRejectModal(liquidations.id))
                        }, "Recusar", 8, _hoisted_22),
                        _createElementVNode("button", {
                          class: "bg-emerald-600 p-2 rounded-lg text-white",
                          onClick: ($event: any) => (openApproveModal(liquidations.id))
                        }, "Aprovar", 8, _hoisted_23)
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_24, "-"))
                ])
              ]))
            }), 256))
          ]))
    ]),
    (liquidations.value?.data?.length)
      ? (_openBlock(), _createBlock(Pagination, {
          key: 0,
          data: liquidations.value?.pagination,
          paginate: getWithdrawals
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    _createVNode(MyModal, {
      width: '330px !important',
      height: '270px !important',
      isOpen: isApproveWithdrawalModalOpen.value || isRejectWithdrawalModalOpen.value,
      toggleModal: closeModals
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_25, [
          (_openBlock(), _createElementBlock("svg", _hoisted_26, [
            (isApproveWithdrawalModalOpen.value)
              ? (_openBlock(), _createElementBlock("path", _hoisted_27))
              : (_openBlock(), _createElementBlock("path", _hoisted_28))
          ])),
          _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Atenção", -1)),
          _createElementVNode("p", _hoisted_29, _toDisplayString(isApproveWithdrawalModalOpen.value ? 'Tem certeza que deseja aprovar o saque?' : 'Tem certeza que deseja reprovar o saque?'), 1),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("button", {
              class: "confirm-button",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (handleWithdrawalUpdate(isApproveWithdrawalModalOpen.value ? 'success' : 'fail')))
            }, " Sim "),
            _createElementVNode("button", {
              class: "cancel-button",
              onClick: closeModals
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createElementVNode("div", {
      class: _normalizeClass(["filter-sidebar bg-white fixed bottom-0 h-full md:1/2 lg:w-1/3 box-shadow font-inter p-6 transition-all duration-200 z-30 transform right-0", isFilterOpen.value ? 'translate-x-0' : 'translate-x-[100%]'])
    }, [
      _createElementVNode("div", _hoisted_31, [
        _cache[14] || (_cache[14] = _createElementVNode("h2", { class: "font-nunito font-bold text-2xl" }, "Filtros", -1)),
        _createElementVNode("button", {
          class: "transition duration-200 hover:text-primary",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (isFilterOpen.value = false))
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_32, _cache[13] || (_cache[13] = [
            _createElementVNode("path", {
              d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
              style: {"fill":"currentcolor"}
            }, null, -1)
          ])))
        ])
      ]),
      _createElementVNode("section", _hoisted_33, [
        _cache[16] || (_cache[16] = _createElementVNode("h3", null, "Filtrar pos status", -1)),
        _createElementVNode("div", _hoisted_34, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(StatusWithdrawalsEnum), (status) => {
            return (_openBlock(), _createElementBlock("label", { for: status }, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                name: "by-stats",
                id: status,
                value: status,
                "onUpdate:modelValue": ($event: any) => ((filter.value.status) = $event)
              }, null, 8, _hoisted_36), [
                [_vModelRadio, filter.value.status]
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("span", null, null, -1)),
              _createElementVNode("p", null, _toDisplayString(status === 'in progress' ? 'in_progress' : _unref(StatusWithdrawalsEnum)[status]), 1)
            ], 8, _hoisted_35))
          }), 256))
        ])
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("input", {
          type: "button",
          value: "Filtrar",
          onClick: _cache[5] || (_cache[5] = ($event: any) => {getWithdrawals(); isFilterOpen.value = false;})
        }),
        _createElementVNode("input", {
          type: "button",
          value: "Limpar",
          onClick: _cache[6] || (_cache[6] = ($event: any) => {filter.value = {}; getWithdrawals(); isFilterOpen.value = false;})
        })
      ])
    ], 2)
  ]))
}
}

})