import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center my-4" }
const _hoisted_2 = {
  key: 0,
  class: "my-10 py-10"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "flex flex-col gap-4" }
const _hoisted_5 = { class: "grid grid-cols-12 border rounded-xl p-4 items-center" }
const _hoisted_6 = { class: "col-span-3 sm:col-span-2" }
const _hoisted_7 = { class: "col-span-3 sm:col-span-2" }
const _hoisted_8 = { class: "col-span-3 sm:col-span-2" }
const _hoisted_9 = { class: "col-span-3 sm:col-span-2" }
const _hoisted_10 = { class: "col-span-3 sm:col-span-2" }
const _hoisted_11 = { class: "col-span-1 flex items-center gap-4" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "p-4 sm:p-8" }
const _hoisted_15 = { class: "flex justify-between items-start" }
const _hoisted_16 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_17 = { class: "grid grid-cols-12 gap-6 rounded-xl" }
const _hoisted_18 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_19 = ["onMousedown"]
const _hoisted_20 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_21 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_22 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_23 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_24 = ["onMousedown"]
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { class: "p-4 sm:p-8" }
const _hoisted_27 = { class: "flex justify-between items-start" }
const _hoisted_28 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_29 = { class: "grid grid-cols-12 gap-6 rounded-xl" }
const _hoisted_30 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_31 = ["onMousedown"]
const _hoisted_32 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_33 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_34 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_35 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_36 = ["onMousedown"]
const _hoisted_37 = ["disabled"]

import { ref, onMounted } from 'vue'
  import FxSelect from '@/components/inputs/FxSelect.vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'
  import IBankAccount from '@/interfaces/organization/IBankAccount'
  import IBank from '@/interfaces/static/IBank'
  import BankAccountTypeEnum, { bankAccountTypeTranslations } from '@/enums/organization/bankAccounts/BankAccountTypeEnum'
  import { useToast } from 'vue-toastification'
  import { vMaska } from 'maska/vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'BankAccountsTab',
  props: {
    organizationId: {}
  },
  setup(__props: any) {

  const props = __props
  const bankAccountForm = ref<Partial<IBankAccount>>({})
  const bankAccountToUpdate = ref<Partial<IBankAccount>>({})
  const isCreateBankAccountModalOpen = ref(false)
  const isEditBankAccountModalOpen = ref(false)
  const isConfirmDeleteModalOpen = ref(false)
  const bankAccounts = ref<Partial<IBankAccount>[]>()
  const banks = ref<IBank[]>(JSON.parse(localStorage.getItem('banks') ?? '[]'))
  const toast = useToast()

  function getBanks() {
    if (!localStorage.getItem('banks')) {
      axiosClient.get(`/static/banks`)
        .then((res) => {
          banks.value = res.data
          localStorage.setItem('banks', JSON.stringify(res.data))
        })
    }
  }

  function getBankAccounts() {
    axiosClient.get(`/organizations/${props.organizationId}/bank-accounts`).then((res) => {
      bankAccounts.value = res.data
      console.log(res)
    })
  }

  function createBankAccounts() {
    const payload = { ...bankAccountForm.value, bankId: bankAccountForm.value.bank?.id }
    delete payload.bank
    axiosClient.post(`/organizations/${props.organizationId}/bank-accounts`, payload).then((res) => {
      if (bankAccounts.value) {
        bankAccounts.value?.push(res.data)
      } else {
        bankAccounts.value = [res.data]
      }
      bankAccountForm.value = {}
      isCreateBankAccountModalOpen.value = false
      toast.success('Conta bancária criada com sucesso')
    })
  }

  function updateBankAccount() {
    const payload = {
      account: bankAccountToUpdate.value.account,
      accountDigit: bankAccountToUpdate.value.accountDigit,
      agency: bankAccountToUpdate.value.agency,
      bankId: bankAccountToUpdate.value.bank?.id ?? bankAccountToUpdate.value.bankId,
      type: bankAccountToUpdate.value.type,
    }

    axiosClient.put(`/organizations/${props.organizationId}/bank-accounts/${bankAccountToUpdate.value.id}`, payload).then((res) => {
      const updateBankAccountIndex = bankAccounts.value!.findIndex((bankAcc) => bankAcc.id === bankAccountToUpdate.value.id)
      bankAccounts.value![updateBankAccountIndex] = bankAccountToUpdate.value
      isEditBankAccountModalOpen.value = false
      toast.success('Conta bancária editada com sucesso')
    })
  }

  function deleteBankAccount() {
    axiosClient.delete(`/organizations/${props.organizationId}/bank-accounts/${bankAccountToUpdate.value.id}`)
      .then(() => {
        bankAccounts.value = bankAccounts.value?.filter((bankAcc) => bankAcc.id !== bankAccountToUpdate.value.id)
        toast.success('Conta bancária deletada com sucesso')
      })
  }

  onMounted(() => {
    getBankAccounts()
    getBanks()
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("div", null, [
        _createElementVNode("h4", { class: "font-bold text-1xl text-292" }, "Gerenciar contas bancárias"),
        _createElementVNode("p", { class: "mb-6 text-sm text-sm" }, "Crie e edite contas bancárias da organização")
      ], -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isCreateBankAccountModalOpen.value = true)),
        class: "bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold"
      }, _cache[11] || (_cache[11] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
            fill: "#fff"
          }),
          _createElementVNode("path", {
            d: "M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z",
            fill: "#fff"
          }),
          _createElementVNode("path", {
            d: "M12 16.75C11.59 16.75 11.25 16.41 11.25 16V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V16C12.75 16.41 12.41 16.75 12 16.75Z",
            fill: "#fff"
          })
        ], -1),
        _createTextVNode(" Criar ")
      ]))
    ]),
    (bankAccounts.value && !bankAccounts.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[13] || (_cache[13] = [
          _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"104\" height=\"104\" viewBox=\"0 0 24 24\" fill=\"none\" class=\"mx-auto\"><path d=\"M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z\" fill=\"#171717\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path opacity=\"0.6\" d=\"M8 11H4V18H8V11Z\" fill=\"#171717\"></path><path opacity=\"0.4\" d=\"M12 11H8V18H12V11Z\" fill=\"#171717\"></path><path opacity=\"0.6\" d=\"M16 11H12V18H16V11Z\" fill=\"#171717\"></path><path opacity=\"0.4\" d=\"M20 11H16V18H20V11Z\" fill=\"#171717\"></path><path d=\"M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z\" fill=\"#171717\"></path><path d=\"M21.37 5.75006L12.37 2.15009C12.17 2.07009 11.83 2.07009 11.63 2.15009L2.63 5.75006C2.28 5.89006 2 6.30006 2 6.68006V10.0001C2 10.5501 2.45 11.0001 3 11.0001H21C21.55 11.0001 22 10.5501 22 10.0001V6.68006C22 6.30006 21.72 5.89006 21.37 5.75006ZM12 8.50006C11.17 8.50006 10.5 7.83006 10.5 7.00006C10.5 6.17006 11.17 5.50006 12 5.50006C12.83 5.50006 13.5 6.17006 13.5 7.00006C13.5 7.83006 12.83 8.50006 12 8.50006Z\" fill=\"#171717\"></path></svg><h4 class=\"font-semibold text-292 text-xl text-center\">Nenhum conta cadastrado</h4><p class=\"text-zinc-400 text-center text-sm\">Clique em nova conta para cadastrar</p>", 3)
        ])))
      : (_openBlock(), _createElementBlock("table", _hoisted_3, [
          _cache[16] || (_cache[16] = _createElementVNode("thead", { class: "hidden sm:grid grid-cols-12 p-4" }, [
            _createElementVNode("th", { class: "col-span-2" }, "Banco"),
            _createElementVNode("th", { class: "col-span-2" }, "Agência"),
            _createElementVNode("th", { class: "col-span-2" }, "Tipo"),
            _createElementVNode("th", { class: "col-span-2" }, "Conta"),
            _createElementVNode("th", { class: "col-span-2" }, "Digito da Conta"),
            _createElementVNode("th", { class: "col-span-2" }, "Ações")
          ], -1)),
          _createElementVNode("tbody", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bankAccounts.value, (bankAccount) => {
              return (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                _createElementVNode("td", _hoisted_6, _toDisplayString(bankAccount.bankName), 1),
                _createElementVNode("td", _hoisted_7, _toDisplayString(bankAccount.agency), 1),
                _createElementVNode("td", _hoisted_8, _toDisplayString(_unref(bankAccountTypeTranslations)[bankAccount.type!]), 1),
                _createElementVNode("td", _hoisted_9, _toDisplayString(bankAccount.account), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(bankAccount.accountDigit), 1),
                _createElementVNode("td", _hoisted_11, [
                  _createElementVNode("button", {
                    class: "hover:stroke-primary",
                    onClick: ($event: any) => {isEditBankAccountModalOpen.value = true; bankAccountToUpdate.value = { ...bankAccount }}
                  }, _cache[14] || (_cache[14] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "25",
                      height: "24",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13",
                        stroke: "#171717",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      }),
                      _createElementVNode("path", {
                        d: "M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z",
                        stroke: "#171717",
                        "stroke-width": "1.5",
                        "stroke-miterlimit": "10",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      }),
                      _createElementVNode("path", {
                        d: "M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002",
                        stroke: "#171717",
                        "stroke-width": "1.5",
                        "stroke-miterlimit": "10",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      })
                    ], -1)
                  ]), 8, _hoisted_12),
                  _createElementVNode("button", {
                    class: "hover:stroke-primary",
                    onClick: ($event: any) => {isConfirmDeleteModalOpen.value = true; bankAccountToUpdate.value = bankAccount}
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        fill: "none",
                        d: "M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5",
                        stroke: "#292F31",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      })
                    ], -1)
                  ]), 8, _hoisted_13)
                ])
              ]))
            }), 256))
          ])
        ])),
    _createVNode(MyModal, {
      height: '37rem',
      width: '40rem',
      isOpen: isCreateBankAccountModalOpen.value,
      toggleModal: (v) => (isCreateBankAccountModalOpen.value = v)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _cache[18] || (_cache[18] = _createElementVNode("div", null, [
              _createElementVNode("h4", { class: "font-bold text-1xl" }, "Criar conta bancária"),
              _createElementVNode("p", { class: "mb-6 text-sm text-sm" }, "Campos com (*) são obrigatórios")
            ], -1)),
            _createElementVNode("button", {
              class: "transition duration-200 hover:text-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isCreateBankAccountModalOpen.value = false))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_16, _cache[17] || (_cache[17] = [
                _createElementVNode("path", {
                  d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                  style: {"fill":"currentcolor"}
                }, null, -1)
              ])))
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[19] || (_cache[19] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Banco", -1)),
              _createVNode(FxSelect, {
                name: 'bankId',
                currentValue: bankAccountForm.value.bank?.name ?? null
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banks.value, (bank, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onMousedown: ($event: any) => (bankAccountForm.value.bank = bank)
                    }, _toDisplayString(bank.name), 41, _hoisted_19))
                  }), 128))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[20] || (_cache[20] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Agência", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((bankAccountForm.value.agency) = $event)),
                placeholder: "ex: 1234",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, bankAccountForm.value.agency],
                [_unref(vMaska), '####']
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[21] || (_cache[21] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Conta", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((bankAccountForm.value.account) = $event)),
                placeholder: "ex: 6078643",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, bankAccountForm.value.account]
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _cache[22] || (_cache[22] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Digito da Conta", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((bankAccountForm.value.accountDigit) = $event)),
                placeholder: "ex: 2",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, bankAccountForm.value.accountDigit],
                [_unref(vMaska), '#']
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[23] || (_cache[23] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Tipo Conta", -1)),
              _createVNode(FxSelect, {
                name: 'bankAccountType',
                currentValue: _unref(bankAccountTypeTranslations)[bankAccountForm.value.type!] ?? null
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(BankAccountTypeEnum), (type, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onMousedown: ($event: any) => (bankAccountForm.value.type = type)
                    }, _toDisplayString(_unref(bankAccountTypeTranslations)[type]), 41, _hoisted_24))
                  }), 128))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ])
          ]),
          _createElementVNode("button", {
            disabled: !bankAccountForm.value.bank?.name || !bankAccountForm.value.agency || !bankAccountForm.value.account || !bankAccountForm.value.accountDigit || !bankAccountForm.value.type,
            class: "bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center",
            onClick: createBankAccounts
          }, _cache[24] || (_cache[24] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M12 16.75C11.59 16.75 11.25 16.41 11.25 16V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V16C12.75 16.41 12.41 16.75 12 16.75Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Criar conta ")
          ]), 8, _hoisted_25)
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      height: '37rem',
      width: '40rem',
      isOpen: isEditBankAccountModalOpen.value,
      toggleModal: (v) => (isEditBankAccountModalOpen.value = v)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _cache[26] || (_cache[26] = _createElementVNode("div", null, [
              _createElementVNode("h4", { class: "font-bold text-1xl" }, "Editar conta bancária"),
              _createElementVNode("p", { class: "mb-6 text-sm text-sm" }, "Campos com (*) são obrigatórios")
            ], -1)),
            _createElementVNode("button", {
              class: "transition duration-200 hover:text-primary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (isEditBankAccountModalOpen.value = false))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_28, _cache[25] || (_cache[25] = [
                _createElementVNode("path", {
                  d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                  style: {"fill":"currentcolor"}
                }, null, -1)
              ])))
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _cache[27] || (_cache[27] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Banco", -1)),
              _createVNode(FxSelect, {
                name: 'bankId',
                currentValue: bankAccountToUpdate.value.bankName ?? null
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banks.value, (bank, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onMousedown: ($event: any) => {bankAccountToUpdate.value.bank = bank; bankAccountToUpdate.value.bankName = bank.name}
                    }, _toDisplayString(bank.name), 41, _hoisted_31))
                  }), 128))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _cache[28] || (_cache[28] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Agência", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((bankAccountToUpdate.value.agency) = $event)),
                placeholder: "ex: 6078643",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, bankAccountToUpdate.value.agency],
                [_unref(vMaska), '####']
              ])
            ]),
            _createElementVNode("div", _hoisted_33, [
              _cache[29] || (_cache[29] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Conta", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((bankAccountToUpdate.value.account) = $event)),
                placeholder: "ex: 1234",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, bankAccountToUpdate.value.account]
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _cache[30] || (_cache[30] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Digito da Conta", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((bankAccountToUpdate.value.accountDigit) = $event)),
                placeholder: "ex: 3",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, bankAccountToUpdate.value.accountDigit],
                [_unref(vMaska), '#']
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _cache[31] || (_cache[31] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Tipo Conta", -1)),
              _createVNode(FxSelect, {
                name: 'bankAccountType',
                currentValue: _unref(bankAccountTypeTranslations)[bankAccountToUpdate.value.type!] ?? null
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(BankAccountTypeEnum), (type, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onMousedown: ($event: any) => (bankAccountToUpdate.value.type = type)
                    }, _toDisplayString(_unref(bankAccountTypeTranslations)[type]), 41, _hoisted_36))
                  }), 128))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ])
          ]),
          _createElementVNode("button", {
            disabled: !bankAccountToUpdate.value.agency || !bankAccountToUpdate.value.account || !bankAccountToUpdate.value.accountDigit || !bankAccountToUpdate.value.type,
            class: "bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center",
            onClick: updateBankAccount
          }, _cache[32] || (_cache[32] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M12 16.75C11.59 16.75 11.25 16.41 11.25 16V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V16C12.75 16.41 12.41 16.75 12 16.75Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Salvar alterações ")
          ]), 8, _hoisted_37)
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      width: '330px !important',
      height: '260px !important',
      isOpen: isConfirmDeleteModalOpen.value,
      "class-name": 'confirm-delete',
      toggleModal: (v) => isConfirmDeleteModalOpen.value = v,
      zIndex: 100
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _cache[33] || (_cache[33] = _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "52",
            height: "52",
            viewBox: "0 0 52 52",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M42.2722 12.6747L29.4021 5.24301C27.3005 4.02967 24.7005 4.02967 22.5771 5.24301L9.72881 12.6747C7.62715 13.888 6.32715 16.1413 6.32715 18.5897V33.4097C6.32715 35.8363 7.62715 38.0897 9.72881 39.3247L22.5988 46.7563C24.7005 47.9697 27.3005 47.9697 29.4238 46.7563L42.2938 39.3247C44.3955 38.1113 45.6955 35.858 45.6955 33.4097V18.5897C45.6738 16.1413 44.3738 13.9097 42.2722 12.6747ZM24.3755 16.7913C24.3755 15.903 25.1121 15.1663 26.0005 15.1663C26.8888 15.1663 27.6255 15.903 27.6255 16.7913V28.1663C27.6255 29.0547 26.8888 29.7913 26.0005 29.7913C25.1121 29.7913 24.3755 29.0547 24.3755 28.1663V16.7913ZM27.9938 36.0313C27.8855 36.2913 27.7338 36.5297 27.5388 36.7463C27.1271 37.158 26.5855 37.3747 26.0005 37.3747C25.7188 37.3747 25.4371 37.3097 25.1771 37.2013C24.8955 37.093 24.6788 36.9413 24.4621 36.7463C24.2671 36.5297 24.1155 36.2913 23.9855 36.0313C23.8771 35.7713 23.8338 35.4897 23.8338 35.208C23.8338 34.6447 24.0505 34.0813 24.4621 33.6697C24.6788 33.4747 24.8955 33.323 25.1771 33.2147C25.9788 32.868 26.9321 33.063 27.5388 33.6697C27.7338 33.8863 27.8855 34.103 27.9938 34.3847C28.1021 34.6447 28.1671 34.9263 28.1671 35.208C28.1671 35.4897 28.1021 35.7713 27.9938 36.0313Z",
              fill: "#A30711"
            })
          ], -1)),
          _cache[34] || (_cache[34] = _createElementVNode("h1", null, "Cuidado!", -1)),
          _cache[35] || (_cache[35] = _createElementVNode("h2", null, "Deseja mesmo deletar esse endereço?", -1)),
          _createElementVNode("section", null, [
            _createElementVNode("input", {
              autocomplete: "off",
              onClick: _cache[9] || (_cache[9] = ($event: any) => {isConfirmDeleteModalOpen.value = false; deleteBankAccount();}),
              type: "button",
              value: "Sim"
            }),
            _createElementVNode("button", {
              onClick: _cache[10] || (_cache[10] = ($event: any) => {isConfirmDeleteModalOpen.value = false;})
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"])
  ], 64))
}
}

})