import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-inter edit-product"
}
const _hoisted_2 = { class: "flex flex-col gap-8" }
const _hoisted_3 = { class: "grid grid-cols-12 gap-4 edit-product__container" }
const _hoisted_4 = { class: "flex flex-col gap-2 col-span-6" }
const _hoisted_5 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_6 = ["onMousedown"]
const _hoisted_7 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_8 = ["onMousedown"]
const _hoisted_9 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex flex-col gap-6" }
const _hoisted_12 = { class: "flex gap-4 items-center" }
const _hoisted_13 = { class: "flex gap-4" }
const _hoisted_14 = { class: "flex flex-col gap-6" }
const _hoisted_15 = { class: "flex flex-col gap-4" }
const _hoisted_16 = { class: "flex gap-4 flex-col sm:flex-row" }
const _hoisted_17 = { class: "flex gap-4" }
const _hoisted_18 = { class: "flex gap-4" }
const _hoisted_19 = { class: "flex gap-4" }
const _hoisted_20 = { class: "flex gap-4" }
const _hoisted_21 = { class: "flex flex-col gap-6" }
const _hoisted_22 = { class: "grid grid-cols-12 gap-4" }
const _hoisted_23 = { class: "col-span-12 sm:col-span-4 flex flex-col gap-2" }
const _hoisted_24 = { class: "col-span-12 sm:col-span-4 flex flex-col gap-2" }
const _hoisted_25 = { class: "col-span-12 sm:col-span-4 flex flex-col gap-2" }

import axiosClient from '@/api/axios'
  import FxSelect from '../../../components/inputs/FxSelect.vue'
  import MySwitch from '../../../components/inputs/MySwitch.vue'
  import { onMounted, ref } from 'vue'
  import { useHoopay } from '@/hooks/Hoopay'
  import PaymentTypeEnum, { paymentTypeTranslations } from '@/enums/product/PaymentTypeEnum'
  import IProduct from '@/interfaces/product/IProduct'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'EditProductView',
  props: {
    productId: {},
    organizationId: {}
  },
  setup(__props: any) {

  const props = __props
  const product = ref<IProduct>()
  const { listproductFormats, productFormats, listProductCategories, productCategories } = useHoopay()

  function getProduct(): void {
    axiosClient.get(`/organizations/products/${props.productId}`).then((res: any) => {
      product.value = res.data
      listProductCategories(product.value!.organizationId)
    })
  }
  onMounted(() => {
    getProduct()
    listproductFormats()
  })

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (product.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_RouterLink, {
          to: { name: 'products' },
          class: "inline-flex items-center gap-2 go-back hover:stroke-fff"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("div", { class: "bg-f5 p-2 rounded-full icon-wrapper transition-all duration-200" }, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none",
                stroke: "#292f31",
                class: "transition-stroke duration-200 hover:parent:stroke-fff stroke-292"
              }, [
                _createElementVNode("path", {
                  d: "M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67",
                  "stroke-width": "1.5",
                  "stroke-miterlimit": "10",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ])
            ], -1),
            _createElementVNode("h1", { class: "font-bold text-2xl font-nunito transition-color duration-200" }, "Editar produto", -1)
          ])),
          _: 1
        }),
        _cache[21] || (_cache[21] = _createElementVNode("p", { class: "mb-6 text-sm" }, "Campos com (*) são obrigatórios", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[5] || (_cache[5] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm input-primary"
              }, "Título", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((product.value.title) = $event)),
                placeholder: "ex: Secador",
                class: "border border-d5 p-3 rounded-xl w-full input-primary"
              }, null, 512), [
                [_vModelText, product.value.title]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Categoria", -1)),
              _createVNode(FxSelect, {
                name: 'categoryId',
                currentValue: _unref(productCategories).find((c) => c.id == product.value!.categoryId)?.title ?? null
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(productCategories), (category, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onMousedown: ($event: any) => (product.value.categoryId = category.id)
                    }, _toDisplayString(category.title), 41, _hoisted_6))
                  }), 128))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Tipo de produto", -1)),
              _createVNode(FxSelect, {
                name: 'formatId',
                currentValue: _unref(productFormats).find((c) => c.id == product.value!.formatId)?.title ?? null
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(productFormats), (format, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      onMousedown: ($event: any) => (product.value.formatId = format.id)
                    }, _toDisplayString(format.title), 41, _hoisted_8))
                  }), 128))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[8] || (_cache[8] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-sm"
              }, "Tipo de pagamento", -1)),
              _createVNode(FxSelect, {
                name: 'formatId',
                currentValue: _unref(paymentTypeTranslations)[product.value.typePayment]
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(PaymentTypeEnum), (paymentType) => {
                    return (_openBlock(), _createElementBlock("li", {
                      onClick: ($event: any) => (product.value.typePayment = paymentType)
                    }, _toDisplayString(_unref(paymentTypeTranslations)[paymentType]), 9, _hoisted_10))
                  }), 256))
                ]),
                _: 1
              }, 8, ["currentValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "font-semibold text-1xl" }, "Configurações", -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(MySwitch),
                _cache[9] || (_cache[9] = _createElementVNode("label", {
                  class: "text-sm",
                  for: ""
                }, "@ Produto ativado", -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[16] || (_cache[16] = _createElementVNode("h2", { class: "font-semibold text-1xl" }, "Checkout", -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(MySwitch),
                  _cache[11] || (_cache[11] = _createElementVNode("label", {
                    class: "text-sm",
                    for: ""
                  }, "@ Pedir endereço do cliente", -1))
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(MySwitch, {
                    id: 'billet',
                    checked: product.value.billet == 'accept'
                  }, null, 8, ["checked"]),
                  _cache[12] || (_cache[12] = _createElementVNode("label", {
                    class: "text-sm",
                    for: ""
                  }, "Boleto ativado", -1))
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(MySwitch, {
                    id: 'pix',
                    checked: product.value.pix == 'accept'
                  }, null, 8, ["checked"]),
                  _cache[13] || (_cache[13] = _createElementVNode("label", {
                    class: "text-sm",
                    for: ""
                  }, "Pix ativado", -1))
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(MySwitch, {
                    id: 'creditCard',
                    checked: product.value.creditCard == 'accept'
                  }, null, 8, ["checked"]),
                  _cache[14] || (_cache[14] = _createElementVNode("label", {
                    class: "text-sm",
                    for: ""
                  }, "Cartão ativado", -1))
                ])
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "flex flex-col gap-3" }, [
                _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-sm input-primary"
                }, "@ Descrição na fatura"),
                _createElementVNode("input", {
                  type: "text",
                  placeholder: "ex: fatura da sua conta",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[20] || (_cache[20] = _createElementVNode("h2", { class: "font-semibold text-1xl" }, "Suporte", -1)),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _cache[17] || (_cache[17] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-sm input-primary"
                }, "Telefone de suporte", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((product.value.contactPhone) = $event)),
                  type: "text",
                  placeholder: "ex: +55 45 99999-9999",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, product.value.contactPhone]
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _cache[18] || (_cache[18] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-sm input-primary"
                }, "Email de suporte", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((product.value.contactEmail) = $event)),
                  type: "text",
                  placeholder: "ex: email@suporte.com",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, product.value.contactEmail]
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _cache[19] || (_cache[19] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-sm input-primary"
                }, "Site do produto", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((product.value.website) = $event)),
                  type: "text",
                  placeholder: "ex: https://site.com",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, product.value.website]
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})