<template>
  <div class="font-inter edit-order" v-if="order">
    <RouterLink :to="{ name: 'orders' }" class="inline-flex items-center gap-2 go-back hover:stroke-fff">
      <div class="bg-f5 p-2 rounded-full icon-wrapper transition-all duration-200">
        <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#292f31"
              class="transition-stroke duration-200 hover:parent:stroke-fff stroke-292"
            >
              <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </div>
      <h1 class="font-bold text-2xl font-nunito transition-color duration-200">Visualizar pedido</h1>
    </RouterLink>
    <p class="mb-6 text-sm">Campos com (*) são obrigatórios</p>
    <div class="flex items-center mb-6 tabs">
      <button @click="currentTab = 'details'">
        <p :class="currentTab == 'details' ? 'active' : ''">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style="min-width: 24px;"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 8V12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 16H12.01" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> -->
          Detalhes
        </p>
      </button>
      <button @click="currentTab = 'client'">
        <p :class="currentTab == 'client' ? 'active' : ''">Cliente</p>
      </button>
      <button @click="currentTab = 'product'">
        <p :class="currentTab == 'product' ? 'active' : ''">Produto</p>
      </button>
      <button @click="currentTab = 'tracking'">
        <p :class="currentTab == 'tracking' ? 'active' : ''">Rastreio</p>
      </button>
      <button @click="currentTab = 'comissions'">
        <p :class="currentTab == 'comissions' ? 'active' : ''">Comissões</p>
      </button>
      <button @click="currentTab = 'infoAgent'">
        <p :class="currentTab == 'infoAgent' ? 'active' : ''">Info Agent</p>
      </button>
      <button @click="currentTab = 'options'">
        <p :class="currentTab == 'options' ? 'active' : ''">Opções</p>
      </button>
    </div>

    <div v-if="currentTab == 'details'">
      <div class="grid grid-cols-12 gap-6 bg-gray-100 p-4 rounded-xl">
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Status</p>
          <div class="flex items-center gap-2">
            <!-- <div class="bg-emerald-500 rounded-full p-1 flex justify-center items-center" style="width: 1.5rem; height: 1.5rem">
              <svg viewBox="0 0 24 24" role="presentation" style="width: 1rem; height: 1rem; z-index: 50">
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" style="fill: rgb(255, 255, 255)"></path>
              </svg>
            </div> -->
            <p class="badge" :class="'bg-' + paymentStatusDetails[order.order.statusId].color">{{ paymentStatusDetails[order.order.statusId].name }}</p>
          </div>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Método de pagamento</p>
          <div class="flex items-center gap-2">
            <svg v-if="order?.order.methodId === PaymentMethodEnum.PIX" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="#949494" class="pix">
              <g clip-path="url(#clip0_3119_239)">
                <path
                  d="M17.9361 17.5931C17.4925 17.5946 17.053 17.5083 16.643 17.339C16.233 17.1697 15.8606 16.9209 15.5474 16.6069L12.0968 13.1545C11.9748 13.0378 11.8124 12.9727 11.6436 12.9727C11.4747 12.9727 11.3124 13.0378 11.1904 13.1545L7.72856 16.6163C7.41544 16.9307 7.04313 17.1799 6.63313 17.3496C6.22314 17.5193 5.78359 17.6061 5.33987 17.6049H4.66406L9.03643 21.9773C9.69218 22.6315 10.5807 22.999 11.507 22.999C12.4333 22.999 13.3218 22.6315 13.9776 21.9773L18.3588 17.5954L17.9361 17.5931Z"
                ></path>
                <path
                  d="M5.34046 5.39248C5.78419 5.39126 6.22376 5.47799 6.63376 5.64768C7.04376 5.81736 7.41607 6.06663 7.72915 6.38107L11.191 9.84408C11.3113 9.96398 11.4743 10.0313 11.6442 10.0313C11.8141 10.0313 11.977 9.96398 12.0974 9.84408L15.5468 6.39467C15.8596 6.07975 16.2318 5.83009 16.6419 5.66018C17.0519 5.49027 17.4916 5.4035 17.9355 5.4049H18.3494L13.9681 1.02366C13.6437 0.699132 13.2586 0.441691 12.8347 0.266048C12.4108 0.0904048 11.9564 0 11.4975 0C11.0387 0 10.5843 0.0904048 10.1604 0.266048C9.73651 0.441691 9.35136 0.699132 9.02697 1.02366L4.66406 5.39248H5.34046Z"
                ></path>
                <path
                  d="M21.9765 9.03094L19.3289 6.38328C19.2692 6.40768 19.2054 6.42052 19.1409 6.42112H17.9376C17.3112 6.4227 16.7107 6.67152 16.2667 7.11348L12.8167 10.5641C12.663 10.7178 12.4805 10.8398 12.2796 10.923C12.0788 11.0062 11.8635 11.049 11.6461 11.049C11.4286 11.049 11.2133 11.0062 11.0125 10.923C10.8116 10.8398 10.6291 10.7178 10.4754 10.5641L7.01235 7.10343C6.56848 6.66125 5.96798 6.41221 5.34145 6.41047H3.8633C3.80255 6.40913 3.74251 6.39713 3.68592 6.375L1.02171 9.03567C0.367439 9.69142 0 10.5799 0 11.5062C0 12.4326 0.367439 13.3211 1.02171 13.9768L3.68238 16.6375C3.73885 16.615 3.79896 16.6029 3.85975 16.602H5.34204C5.96856 16.6002 6.56904 16.3512 7.01294 15.9091L10.4748 12.4472C10.7904 12.1463 11.2097 11.9785 11.6458 11.9785C12.0818 11.9785 12.5011 12.1463 12.8167 12.4472L16.2662 15.8961C16.7101 16.338 17.3106 16.5868 17.9371 16.5884H19.1409C19.2054 16.5888 19.2692 16.6017 19.3289 16.6263L21.9765 13.9786C22.3011 13.6542 22.5585 13.2691 22.7342 12.8451C22.9098 12.4212 23.0002 11.9669 23.0002 11.508C23.0002 11.0492 22.9098 10.5948 22.7342 10.1709C22.5585 9.74699 22.3011 9.36183 21.9765 9.03744"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_3119_239">
                  <rect width="23" height="23" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <p>{{ PaymentMethodDetails[order?.order.methodId].name }}</p>
          </div>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Valor dos Produtos</p>
          <p>{{ order.order.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Pago pelo Cliente</p>
          <p>{{ ((order.order.amount ?? 0) + order.order.interestFee)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Data de Liberação</p>
          <p>{{ order.order.releaseAt ? new Date(order.order.releaseAt)?.toLocaleString() : 'Sem data de liberação' }}</p>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Data de Compra</p>
          <p>{{ new Date(order.order.createdAt)?.toLocaleString() }}</p>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Ùltima atualização</p>
          <p>{{ new Date(order.order.updatedAt)?.toLocaleString() }}</p>
        </div>
        <!-- <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">@ QRCode PIX</p>
          <input
            type="text"
            placeholder="ex: 9289834govbr"
            value="00020101021226790014br.gov.bcb.pix2557brcode.starkinfra.com/v2/bbb21faf932344f69c56bf6ee3b877a45204000053039865802BR5925Suitpay Instituicao de Pa6007Goiania62070503***6304A21E"
            class="border border-d5 p-3 rounded-xl w-full input-primary"
          />
        </div> -->
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Código de Rastreio</p>
          <p v-for="shipment in order.shipments">{{ shipment.trackingCode }}<br></p>
        </div>
        <div class="col-span-3">
          <p class="font-semibold text-sm input-primary">Antifraude (ClearSale)</p>
          <div class="flex items-center gap-2">
            <p>Risco:</p>
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18.5398 4.12009L13.0398 2.06009C12.4698 1.85009 11.5398 1.85009 10.9698 2.06009L5.4698 4.12009C4.4098 4.52009 3.5498 5.76009 3.5498 6.89009V14.9901C3.5498 15.8001 4.0798 16.8701 4.7298 17.3501L10.2298 21.4601C11.1998 22.1901 12.7898 22.1901 13.7598 21.4601L19.2598 17.3501C19.9098 16.8601 20.4398 15.8001 20.4398 14.9901V6.89009C20.4498 5.76009 19.5898 4.52009 18.5398 4.12009ZM15.4798 9.72009L11.1798 14.0201C11.0298 14.1701 10.8398 14.2401 10.6498 14.2401C10.4598 14.2401 10.2698 14.1701 10.1198 14.0201L8.5198 12.4001C8.2298 12.1101 8.2298 11.6301 8.5198 11.3401C8.8098 11.0501 9.2898 11.0501 9.5798 11.3401L10.6598 12.4201L14.4298 8.65009C14.7198 8.36009 15.1998 8.36009 15.4898 8.65009C15.7798 8.94009 15.7798 9.43009 15.4798 9.72009Z"
                  class="fill-emerald-500"
                />
              </svg>
              <p>@ 0%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentTab == 'client'">
      <div class="grid grid-cols-12 gap-4">
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Nome</label>
          <input type="text" placeholder="ex: João" :value="order.client.name" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Email</label>
          <input type="text" placeholder="ex: joao@gmai.com" :value="order.client.email" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">CPF/CNPJ</label>
          <input type="tel" placeholder="ex: 000.000.000-00" v-model="order.client.document" v-maska="(order.client.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##'" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Telefone</label>
          <input type="tel" placeholder="ex: (99) 9 9999-9999" :value="order.client.phoneNumber" v-maska="'(##) # ####-####'" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">CEP</label>
          <input type="text" placeholder="ex: 20543462" v-maska="'#####-###'" :value="order.address?.zipCode" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Endereço</label>
          <input type="text" placeholder="ex: São paulo, Rua Palmares..." :value="order.address?.street" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Complemento</label>
          <input type="text" placeholder="ex: Alto" :value="order.address?.complement" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Número</label>
          <input type="text" placeholder="ex: 8432" :value="order.address?.number" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Bairro</label>
          <input type="text" placeholder="ex: Aldeota" :value="order.address?.neighborhood" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Cidade</label>
          <input type="text" placeholder="ex: Fortaleza" :value="order.address?.city" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-3">
          <label for="" class="font-semibold text-sm input-primary">Estado</label>
          <input type="text" placeholder="ex: Ceára" :value="order.address?.state" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
      </div>
    </div>

    <div v-if="currentTab == 'product'">
      <div class="grid grid-cols-12 gap-6 p-4 rounded-xl">
        <table class="w-full col-span-12">
          <thead class="grid grid-cols-6 p-4 hidden sm:grid">
            <th class="col-span-1">Título</th>
            <th class="col-span-1">Imagem</th>
            <th class="col-span-1">Valor (unitário)</th>
            <th class="col-span-1">Valor</th>
            <th class="col-span-1">Desconto</th>
            <th class="col-span-1">Quantidade</th>
          </thead>
          <tbody class="flex flex-col gap-4">
            <tr v-for="product in order.products" class="grid grid-cols-6 border rounded-xl p-4 items-center product-tr">
              <td class="col-span-2 sm:col-span-1">{{ product.title }}</td>
              <td class="col-span-2 sm:col-span-1">
                <img v-if="product.source" width="80" height="80" :src="product.source" alt="">
              </td>
              <td class="col-span-2 sm:col-span-1">{{ product.unitPrice?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ product.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ product.discount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ product.quantity }}x</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="currentTab == 'tracking'">
      <div class="grid grid-cols-12 gap-6 p-4 rounded-xl">
        <table class="w-full col-span-12">
          <thead class="grid grid-cols-5 p-4 hidden sm:grid">
            <th class="col-span-1">Valor</th>
            <th class="col-span-1">Custo</th>
            <th class="col-span-1">Código</th>
            <th class="col-span-1">Status</th>
            <th class="col-span-1">Dimensões</th>
          </thead>
          <tbody class="flex flex-col gap-4">
            <tr v-for="shipment in order.shipments" class="grid grid-cols-5 border rounded-xl p-4">
              <td class="col-span-2 sm:col-span-1">{{ shipment.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ shipment.cost?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ shipment.trackingCode }}</td>
              <td class="col-span-2 sm:col-span-1">{{ shipment.trackingStatus }}</td>
              <td class="col-span-2 sm:col-span-1">
                {{ shipment.dimensions?.width || shipment.dimensions?.height ? `Largura: ${shipment.dimensions.width}, altura: ${shipment.dimensions.height}` : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="currentTab == 'comissions'">
      <div class="grid grid-cols-12 gap-6 p-4 rounded-xl">
        <table class="w-full col-span-12">
          <thead class="grid grid-cols-4 p-4 hidden sm:grid">
            <th class="col-span-1">Tipo</th>
            <th class="col-span-1">Valor Base</th>
            <th class="col-span-1">Valor da comissão</th>
            <th class="col-span-1">Perc(%) da comissão</th>
          </thead>
          <tbody class="flex flex-col gap-4">
            <tr v-for="comission in order.amount" class="grid grid-cols-4 border rounded-xl p-4 items-center">
              <td class="col-span-2 sm:col-span-1">{{ comission.type }}</td>
              <td class="col-span-2 sm:col-span-1">{{ comission.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ comission.amountCommission?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              <td class="col-span-2 sm:col-span-1">{{ ((comission.amountCommission/100) * comission.amount) * 100 }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="currentTab == 'infoAgent'">
      <!-- <div class="grid grid-cols-12 gap-6 bg-gray-100 p-4 rounded-xl">
                <div class="col-span-3">
                    <p class="font-semibold text-sm input-primary">Título</p>
                    <p></p>
                </div>
                <div class="col-span-3">
                    <p class="font-semibold text-sm input-primary">Valor</p>
                    <p></p>
                </div>
            </div> -->
      <table class="w-full mt-4">
        <thead>
          <tr class="grid grid-cols-12">
            <th class="col-span-2 border">Ip</th>
            <th class="col-span-2 border">Navegador</th>
            <th class="col-span-1 border">Versão</th>
            <th class="col-span-2 border">Sistema Operacional</th>
            <th class="col-span-2 border">Cidade</th>
            <th class="col-span-1 border">Estado</th>
            <th class="col-span-2 border">País</th>
          </tr>
        </thead>
        <tbody class="flex flex-col gap-4">
          <tr class="justify-between grid grid-cols-12">
            <td class="col-span-2 border">{{ order.data.ip }}</td>
            <td class="col-span-2 border">{{ order.data.browser }}</td>
            <td class="col-span-1 border">{{ order.data.version }}</td>
            <td class="col-span-2 border">{{ order.data.platform }}</td>
            <td class="col-span-2 border">@ São Paulo</td>
            <td class="col-span-1 border">@ SP</td>
            <td class="col-span-2 border">@ Brasil</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import axiosClient from '@/api/axios'
  import { onMounted, ref } from 'vue'
  import IGetOrderResult from '@/interfaces/organization/order/IGetOrderResult'
  import { PaymentMethodDetails, PaymentMethodEnum } from '@/enums/PaymentMethodEnum'
  import { vMaska } from 'maska/vue'
  import { paymentStatusDetails } from '@/enums/PaymentStatusEnum'

  const props = defineProps<{ organizationId: number; orderId: number }>()
  const currentTab = ref('details')
  const order = ref<IGetOrderResult>()

  function getOrder() {
    axiosClient.get(`/administrator/organizations/${props.organizationId}/orders/${props.orderId}`).then((res) => {
      order.value = res.data
    })
  }

  onMounted(() => {
    getOrder()
  })
</script>

<style lang="scss" scoped>
  .edit-order {
    .badge {
      color: #fff;
      font-weight: 700;
      border-radius: 60px;
      padding: 4px 10px 5px;
      font-size: 13px;
    }
    input[type='text'],
    input[type='number'] {
      width: 100%;
      height: 55px;
      border-radius: 14px;
      border: 1px solid #d3d3d3;
      color: $primary-color;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 15px 20px;
      box-sizing: border-box;
      transition:
        border-color 0.2s,
        color 0.2s,
        background 0.2s;
      &:focus {
        outline: 1px solid $primary-color;
        border: 1px solid $primary-color;
        &::placeholder {
          color: transparent;
        }
      }
      &::placeholder {
        color: $placeholder-color;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: color 0.2s;
      }
    }
    .product-tr {
      min-height: 100px
    }
  }
</style>
