<template>
  <div>
    <div class="flex justify-between items-center my-4">
      <div>
        <h4 class="font-bold text-1xl text-292">Gerenciar sócios</h4>
        <p class="mb-6 text-sm text-sm">Crie e edite os sócios da organização</p>
      </div>
      <button @click="isCreatePartnersModalOpen = true" class="bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M19.49 17.98H16.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18 16.52V19.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Criar
      </button>
    </div>
    <div v-if="partners && !partners?.length" class="my-10 py-10">
      <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 24 24" fill="none" class="mx-auto">
        <path
          d="M12.0699 8.95005C12.0299 8.95005 11.9699 8.95005 11.9199 8.95005C10.8699 8.91005 10.0399 8.06005 10.0399 7.00005C10.0399 5.92005 10.9099 5.05005 11.9899 5.05005C13.0699 5.05005 13.9399 5.93005 13.9399 7.00005C13.9499 8.06005 13.1199 8.92005 12.0699 8.95005Z"
          fill="#171717"
        />
        <path
          d="M9.24994 11.9601C7.91994 12.8501 7.91994 14.3001 9.24994 15.1901C10.7599 16.2001 13.2399 16.2001 14.7499 15.1901C16.0799 14.3001 16.0799 12.8501 14.7499 11.9601C13.2399 10.9601 10.7699 10.9601 9.24994 11.9601Z"
          fill="#171717"
        />
        <path
          opacity="0.4"
          d="M18 2H6C4.34 2 3 3.33 3 4.97V15.88C3 17.52 4.34 18.85 6 18.85H6.76C7.56 18.85 8.32 19.16 8.88 19.72L10.59 21.41C11.37 22.18 12.64 22.18 13.42 21.41L15.13 19.72C15.69 19.16 16.46 18.85 17.25 18.85H18C19.66 18.85 21 17.52 21 15.88V4.97C21 3.33 19.66 2 18 2ZM12 5.05C13.08 5.05 13.95 5.93 13.95 7C13.95 8.06 13.11 8.91 12.07 8.95C12.03 8.95 11.97 8.95 11.92 8.95C10.87 8.91 10.04 8.06 10.04 7C10.05 5.93 10.92 5.05 12 5.05ZM14.75 15.19C13.24 16.2 10.76 16.2 9.25 15.19C7.92 14.31 7.92 12.85 9.25 11.96C10.77 10.95 13.25 10.95 14.75 11.96C16.08 12.85 16.08 14.3 14.75 15.19Z"
          fill="#171717"
        />
      </svg>
      <h4 class="font-semibold text-292 text-xl text-center">Nenhum sócio cadastrado</h4>
      <p class="text-zinc-400 text-center text-sm">Clique em criar para cadastradar um sócio</p>
    </div>
    <table v-else class="w-full">
      <thead class="grid grid-cols-5 p-4 hidden sm:grid">
        <th class="col-span-1">Nome</th>
        <th class="col-span-1">Documento</th>
        <th class="col-span-1">Data de nascimento</th>
        <th class="col-span-1">Porcentagem</th>
        <th class="col-span-1">Ações</th>
      </thead>
      <tbody class="flex flex-col gap-4">
        <tr v-for="partner in partners" class="grid grid-cols-5 border rounded-xl p-4">
          <td class="col-span-2 sm:col-span-1">{{ partner.name }}</td>
          <td class="col-span-2 sm:col-span-1">{{ partner.document }}</td>
          <td class="col-span-2 sm:col-span-1">{{ new Date(partner.dateOfBirth!).toLocaleDateString() }}</td>
          <td class="col-span-2 sm:col-span-1">{{ partner.percentage }}</td>
          <td class="col-span-1 sm:col-span-1 flex gap-4">
            <button class="hover:stroke-primary" @click="isEditPartnerModalOpen = true; partnerToUpdate = { id: partner.id, name: partner.name, document: partner.document, dateOfBirth: partner.dateOfBirth, percentage: partner.percentage }">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary transition-all duration-200"
                />
                <path
                  d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary transition-all duration-200"
                />
                <path
                  d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002"
                  stroke="#171717"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="hover:parent:stroke-primary transition-all duration-200"
                />
              </svg>
            </button>
            <button class="hover:stroke-primary" @click="isConfirmDeleteModalOpen = true; partnerToUpdate = partner">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill='none' d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5" stroke="#292F31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <MyModal :height="'580px'" :width="'40rem'" :isOpen="isCreatePartnersModalOpen" :toggleModal="(v: any) => (isCreatePartnersModalOpen = v)">
      <div class="p-4 sm:p-8">
        <div class="flex justify-between items-start">
          <div>
            <h4 class="font-bold text-1xl">Cadastrar sócio</h4>
            <p class="mb-6 text-sm text-sm">Campos com (*) são obrigatórios</p>
          </div>
          <button class="transition duration-200 hover:text-primary" @click="isCreatePartnersModalOpen = false">
            <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor"></path>
            </svg>
          </button>
        </div>
        <div class="grid grid-cols-12 gap-6 rounded-xl">
          <div class="flex flex-col gap-4 col-span-12 sm:col-span-6">
            <div class="flex flex-col gap-2">
              <label for="" class="font-semibold text-sm input-primary">Nome</label>
              <input type="text" v-model="partnerForm.name" placeholder="ex: João" class="border border-d5 p-3 rounded-xl w-full input-primary" />
            </div>
            <div class="flex flex-col gap-2">
              <label for="" class="font-semibold text-sm input-primary">CPF/CNPJ</label>
              <input type="tel" v-model="partnerForm.document" v-maska="(partnerForm.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##'" placeholder="ex: 000.000.000-00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
            </div>
            <div class="flex flex-col gap-2">
              <label for="" class="font-semibold text-sm input-primary">Porcentagem</label>
              <input type="number" v-model="partnerForm.percentage" placeholder="ex: 30" class="border border-d5 p-3 rounded-xl w-full input-primary" />
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 flex flex-col gap-2">
            <label for="" class="font-semibold text-sm input-primary">Data de nascimento</label>
            <FxDatePicker :min="{ day: 1, month: 1, year: 1930 }" :name="'birthDate'" :setValue="(date) => partnerForm.dateOfBirth = date" />
          </div>
        </div>
        <button :disabled="!partnerForm.name || (partnerForm.document?.length !== 14 && partnerForm.document?.length !== 18) || !partnerForm.dateOfBirth || !partnerForm.percentage" @click="createPartners" class="bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M19.49 17.98H16.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 16.52V19.51" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Cadastrar sócio
        </button>
      </div>
    </MyModal>
    <MyModal :height="'580px'" :width="'40rem'" :isOpen="isEditPartnerModalOpen" :toggleModal="(v: any) => (isEditPartnerModalOpen = v)">
      <div class="p-4 sm:p-8">
        <div class="flex justify-between items-start">
          <div>
            <h4 class="font-bold text-1xl">Editar sócio</h4>
            <p class="mb-6 text-sm text-sm">Campos com (*) são obrigatórios</p>
          </div>
          <button class="transition duration-200 hover:text-primary" @click="isEditPartnerModalOpen = false">
            <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor"></path>
            </svg>
          </button>
        </div>
        <div class="grid grid-cols-12 gap-6 rounded-xl">
          <div class="col-span-12 sm:col-span-6 flex flex-col gap-2">
            <div class="flex flex-col gap-2">
              <label for="" class="font-semibold text-sm input-primary">Nome</label>
              <input type="text" v-model="partnerToUpdate.name" placeholder="ex: João" class="border border-d5 p-3 rounded-xl w-full input-primary" />
            </div>
            <div class="flex flex-col gap-2">
              <label for="" class="font-semibold text-sm input-primary">CPF/CNPJ</label>
              <input type="tel" v-model="partnerToUpdate.document" v-maska="(partnerToUpdate.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##'" placeholder="ex: 000.000.000-00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
            </div>
            <div class="flex flex-col gap-2">
              <label for="" class="font-semibold text-sm input-primary">Porcentagem</label>
              <input type="number" v-model="partnerToUpdate.percentage" placeholder="ex: 30" class="border border-d5 p-3 rounded-xl w-full input-primary" />
            </div>
          </div>
          <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
            <label for="" class="font-semibold text-sm input-primary">Data de nascimento</label>
            <FxDatePicker :min="{ day: 1, month: 1, year: 1930 }" :name="'birthDate'" :setValue="(date) => partnerToUpdate.dateOfBirth = date" />
          </div>
        </div>
        <button @click="updatePartner" :disabled="!partnerToUpdate.name || (partnerToUpdate.document?.length !== 14 && partnerToUpdate.document?.length !== 18) || !partnerToUpdate.dateOfBirth || !partnerToUpdate.percentage" class="bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M15.02 3.01001C14.18 2.37001 13.14 2 12 2C9.24 2 7 4.24 7 7C7 9.76 9.24 12 12 12C14.76 12 17 9.76 17 7"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.2101 15.74L15.67 19.2801C15.53 19.4201 15.4 19.68 15.37 19.87L15.18 21.22C15.11 21.71 15.45 22.05 15.94 21.98L17.29 21.79C17.48 21.76 17.75 21.63 17.88 21.49L21.42 17.95C22.03 17.34 22.32 16.63 21.42 15.73C20.53 14.84 19.8201 15.13 19.2101 15.74Z"
              stroke="#fff"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M18.7002 16.25C19.0002 17.33 19.8402 18.17 20.9202 18.47" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Salvar alterações
        </button>
      </div>
    </MyModal>
    <MyModal :width="'330px !important'" :height="'260px !important'" :isOpen="isConfirmDeleteModalOpen" :class-name="'confirm-delete'" :toggleModal="(v: boolean) => isConfirmDeleteModalOpen = v" :zIndex="100">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
          <path d="M42.2722 12.6747L29.4021 5.24301C27.3005 4.02967 24.7005 4.02967 22.5771 5.24301L9.72881 12.6747C7.62715 13.888 6.32715 16.1413 6.32715 18.5897V33.4097C6.32715 35.8363 7.62715 38.0897 9.72881 39.3247L22.5988 46.7563C24.7005 47.9697 27.3005 47.9697 29.4238 46.7563L42.2938 39.3247C44.3955 38.1113 45.6955 35.858 45.6955 33.4097V18.5897C45.6738 16.1413 44.3738 13.9097 42.2722 12.6747ZM24.3755 16.7913C24.3755 15.903 25.1121 15.1663 26.0005 15.1663C26.8888 15.1663 27.6255 15.903 27.6255 16.7913V28.1663C27.6255 29.0547 26.8888 29.7913 26.0005 29.7913C25.1121 29.7913 24.3755 29.0547 24.3755 28.1663V16.7913ZM27.9938 36.0313C27.8855 36.2913 27.7338 36.5297 27.5388 36.7463C27.1271 37.158 26.5855 37.3747 26.0005 37.3747C25.7188 37.3747 25.4371 37.3097 25.1771 37.2013C24.8955 37.093 24.6788 36.9413 24.4621 36.7463C24.2671 36.5297 24.1155 36.2913 23.9855 36.0313C23.8771 35.7713 23.8338 35.4897 23.8338 35.208C23.8338 34.6447 24.0505 34.0813 24.4621 33.6697C24.6788 33.4747 24.8955 33.323 25.1771 33.2147C25.9788 32.868 26.9321 33.063 27.5388 33.6697C27.7338 33.8863 27.8855 34.103 27.9938 34.3847C28.1021 34.6447 28.1671 34.9263 28.1671 35.208C28.1671 35.4897 28.1021 35.7713 27.9938 36.0313Z" fill="#A30711"/>
        </svg>
        <h1>Cuidado!</h1>
        <h2>Deseja mesmo deletar esse endereço?</h2>
        <section>
          <input autocomplete='off' @click="isConfirmDeleteModalOpen = false; deletePartner();" type="button" value="Sim">
          <button @click="isConfirmDeleteModalOpen = false;">Cancelar</button>
        </section>
      </div>
    </MyModal>
  </div>
</template>

<script setup lang="ts">
  import MyModal from '@/components/modal/MyModal.vue'
  import { ref, onMounted } from 'vue'
  import axiosClient from '@/api/axios'
  import IPartner from '@/interfaces/organization/IPartner'
  import { useToast } from 'vue-toastification'
  import { vMaska } from 'maska/vue'
  import FxDatePicker from '@/components/date/FxDatePicker.vue'


  const partners = ref<Partial<IPartner>[]>()
  const partnerForm = ref<Partial<IPartner>>({})
  const partnerToUpdate = ref<Partial<IPartner>>({})
  const isCreatePartnersModalOpen = ref(false)
  const isEditPartnerModalOpen = ref(false)
  const isConfirmDeleteModalOpen = ref(false)
  const props = defineProps<{ organizationId: number }>()
  const toast = useToast()

  function getPartners() {
    axiosClient.get(`/organizations/${props.organizationId}/partners`).then((res) => {
      partners.value = res.data
    })
  }

  function createPartners() {
    axiosClient.post(`/organizations/${props.organizationId}/partners`, { ...partnerForm.value, document: partnerForm.value.document!.replace(/[\.\-\/]/g, '') }).then((res) => {
      if (partners.value) {
        partners.value.push(res.data)
      } else {
        partners.value = [res.data]
      }
      partnerForm.value = {}
      isCreatePartnersModalOpen.value = false
      toast.success("Sócio criado com sucesso")
    })
  }

  function updatePartner() {
    axiosClient.put(`/organizations/${props.organizationId}/partners/${partnerToUpdate.value.id}`, {
      name: partnerToUpdate.value.name,
      document: partnerToUpdate.value.document!.replace(/[\.\-\/]/g, ''),
      dateOfBirth: partnerToUpdate.value.dateOfBirth,
      percentage: partnerToUpdate.value.percentage,
    }).then((res) => {
      const updatePartnerIndex = partners.value!.findIndex((partner) => partner.id === partnerToUpdate.value.id)
      partners.value![updatePartnerIndex] = partnerToUpdate.value
      isEditPartnerModalOpen.value = false
      toast.success('Sócio editado com sucesso')
    })
  }

  function deletePartner() {
    axiosClient.delete(`/organizations/${props.organizationId}/partners/${partnerToUpdate.value.id}`)
      .then(() => {
        partners.value = partners.value?.filter((bankAcc) => bankAcc.id !== partnerToUpdate.value.id)
        toast.success('Sócio deletada com sucesso')
      })
  }

  onMounted(() => {
    getPartners()
  })
</script>
