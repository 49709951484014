<template>
  <label class="mySelect" :class="{ open: isSelectOpen }" :for="props.for || ''">
    <input
      type="text"
      placeholder="Selecione"
      :value="currentValue"
      @click="isSelectOpen ? closeSelect() : (isSelectOpen = true)"
      @blur="closeSelect"
      :id="props.for || 'my-select'"
      :name="name"
      readonly
    />
    <div>
      <ul>
        <slot />
      </ul>
    </div>
  </label>
</template>

<script setup lang="ts">
  import { defineProps, ref } from 'vue'

  const props = defineProps<{
    name: string
    currentValue: string | null | undefined
    for?: string
  }>()

  /* SCRIPT SELECT */
  const isSelectOpen = ref<boolean>(false)

  const closeSelect = () => {
    setTimeout(() => {
      isSelectOpen.value = false
    }, 80)
  }
</script>

<style lang="scss">
  @import '@/global/scss/variables.scss';

  .mySelect {
    position: relative;
    input {
      width: 100%;
      height: 55px;
      border-radius: 14px;
      border: 1px solid #d3d3d3;
      color: #090909;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 15px 20px;
      box-sizing: border-box;
      transition:
        border-color 0.2s,
        color 0.2s,
        background 0.2s;
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #090909;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: color 0.2s;
      }
    }
    div {
      min-width: 100%;
      max-height: 233px;
      padding: 5px 10px 5px 0;
      display: none;
      flex-direction: column;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      position: absolute;
      top: calc(100% + 5px);
      z-index: 1;
      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 20px;
        margin: 0;
        overflow-y: auto;
        row-gap: 24px;
        &::-webkit-scrollbar {
          width: 8px;
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background: $primary-color;
          border-radius: 8px;
        }
        li {
          display: flex;
          align-items: center;
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: start;
          transition: color 0.2s;
          cursor: pointer;
          &:hover {
            color: $primary-color;
            text-decoration: underline;
          }
        }
      }
    }
    &.open {
      &::before {
        top: 24px;
        border-color: $primary-color;
        transform: rotate(405deg);
      }
      input {
        border: 2px solid $primary-color !important;
        color: $primary-color !important;
        padding: 14px 19px !important;
        &::placeholder {
          color: $primary-color !important;
        }
      }
      div {
        display: flex;
      }
    }
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-left: 2px solid #292d32;
      border-top: 2px solid #292d32;
      border-radius: 2px;
      position: absolute;
      top: 19px;
      right: 30px;
      transform: rotate(225deg);
      transition: border-color 0.2s;
    }
  }
</style>
